import api from '../api.client'

const path = '/api/v2/shop/wishlists'

export default {
  create: (name = undefined, options = {}) =>
    api({ ...options }).post(path, { name }),
  getOne: (variantCode, options = {}) =>
    api(options).get(`/api/v2/shop/product-variants-wishlists/${variantCode}`),
  getAll: (options = {}) => api(options).get(path),
  getList: (token, options = {}) => api(options).get(`${path}/${token}`),
  updateList: ({ token, name }, options) =>
    api(options).patch(`${path}/${token}`, { name }),
  deleteList: (token, options) => api(options).delete(`${path}/${token}`),
  addProduct: ({ token, productId }, options) =>
    api(options).patch(`${path}/${token}/product`, { productId }),
  deleteProduct: ({ token, productId }, options) =>
    api(options).delete(`${path}/${token}/product/${productId}`),
  addVariant: ({ token, productVariantId }, options) =>
    api(options).patch(`${path}/${token}/variant`, { productVariantId }),
  deleteVariant: ({ token, productVariantId }, options) =>
    api(options).delete(`${path}/${token}/productVariants/${productVariantId}`),
  deleteProductFromLists: (productVariantCode, options) =>
    api(options).delete(`${path}/productVariant/${productVariantCode}`)
}
