import omit from 'lodash/omit'
import Entity from './Entity'
import ProductEntity from './Product.entity'
import ProductVariantEntity from './ProductVariant.entity'

class WishlistProductEntity extends Entity {
  quantity?: string
  product?: ProductEntity
  variant?: ProductVariantEntity
  constructor(payload: Partial<WishlistProductEntity>) {
    super(omit(payload, ['product', 'variant']))
    Object.assign(this, omit(payload, ['product', 'variant']))
    if (payload?.product) {
      this.setProduct(payload.product)
    }
    if (payload?.variant) {
      this.setVariant(payload.variant)
    }
  }

  setProduct(product: Partial<ProductEntity>) {
    this.product = new ProductEntity(product)
  }

  setVariant(variant: Partial<ProductVariantEntity>) {
    this.variant = new ProductVariantEntity(variant)
  }
}

export default WishlistProductEntity
