import assign from 'lodash/assign'
import Collection from './Collection'
export default class Entity {
  [key: string]: any
  constructor(payload: any) {
    assign(this, payload)
  }

  static parseApiResponse(response: any | any[]) {
    const data = response?.value || response
    if (data?.length) {
      return data.map((v: any) => new this(v))
    }
    if (data?.['hydra:member']) {
      return new Collection(data, this)
      // return new Entity(response)
    }
    return new this(data)
  }

  toJSON() {
    return { ...this }
  }
}
