import api from '../api.client'

const path = '/api/v2/shop/product-review-reactions'

export default {
  toggleReaction: (data, options) =>
    api(options).post(path, {
      reviewId: data.id,
      reactionType: data.type
    })
}
