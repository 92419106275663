<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6836 8.27163L7.8267 13.6883M1.96981 8.27163L7.8267 13.6883M7.8267 13.6883L7.8267 2.31329"
      stroke="#FF612F"
      stroke-width="2"
      stroke-linecap="square"
    />
  </svg>
</template>
