<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4857_109428)">
      <path
        d="M13.6316 8.68421C13.6316 9.93335 13.2612 11.1544 12.5672 12.1931C11.8732 13.2317 10.8868 14.0412 9.73274 14.5192C8.57868 14.9973 7.30878 15.1223 6.08364 14.8786C4.8585 14.6349 3.73313 14.0334 2.84985 13.1501C1.96658 12.2669 1.36505 11.1415 1.12136 9.91636C0.877663 8.69122 1.00274 7.42132 1.48076 6.26726C1.95879 5.1132 2.7683 4.12681 3.80693 3.43282C4.84555 2.73883 6.06665 2.36842 7.31579 2.36842V8.68421H13.6316Z"
        stroke="#3E3E3E"
        stroke-width="2"
      />
      <path
        d="M9.84211 1C10.5195 1 11.1902 1.13341 11.8159 1.39262C12.4417 1.65183 13.0103 2.03176 13.4893 2.51071C13.9682 2.98967 14.3482 3.55827 14.6074 4.18405C14.8666 4.80984 15 5.48055 15 6.15789H9.84211L9.84211 1Z"
        stroke="#3E3E3E"
        stroke-width="2"
      />
    </g>
    <defs>
      <clipPath id="clip0_4857_109428">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
