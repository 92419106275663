import api from '../api.client'

export default {
  getAll: options => {
    const client = api({ auth: false, ...options })
    return client.get('/api/v2/shop/taxons')
  },
  getOne: (code, options = {}) =>
    api({ auth: false, ...options }).get(`/api/v2/shop/taxons/${code}`),

  getRootTaxons: options =>
    api({ auth: false, ...options }).get('/api/v2/shop/root-taxons'),

  getTopCategories: options =>
    api({ auth: false, ...options }).get(
      '/api/v2/shop/top-categories?itemsPerPage=20'
    ),

  getAllCategories: options =>
    api({ auth: false, ...options }).get(
      '/api/v2/shop/categories?page=1&itemsPerPage=30'
    )
}
