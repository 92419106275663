const getDomain = host => {
  const prefix = /^https?:\/\//i
  return host.replace(prefix, '').split('/')[0].split(':')[0]
}

export default function () {
  const config = useRuntimeConfig()
  if (!config) {
    return {}
  }
  let currentHost = config.public?.originDomain
  if (process.client && !currentHost) {
    currentHost = location.href
  } else if (process.server && !currentHost) {
    currentHost = useRequestHeaders().host
  }
  if (!currentHost) {
    return {}
  }
  const locale = useNuxtApp()?.$i18n?.locale?.value || 'uk'
  // const { locale } = useNuxtApp()?.$i18n
  currentHost = getDomain(currentHost)
  const huntingDomain = getDomain(config.public.huntingDomain)
  const fishingDomain = getDomain(config.public.fishingDomain)

  return reactive({
    currentDomain: currentHost,
    huntingDomain: config.public.huntingDomain,
    fishingDomain: config.public.fishingDomain,
    current: huntingDomain === currentHost ? 'HUNTING' : 'FISHING',
    isHunting: huntingDomain === currentHost,
    isFishing: fishingDomain === currentHost,
    switchToHunting: () => {
      if (process.client) {
        const config = useRuntimeConfig()
        // const { locale } = useNuxtApp()?.$i18n
        // const { locale } = useI18n()
        navigateTo(
          locale === 'uk'
            ? config.public.huntingDomain
            : config.public.huntingDomain + '/ru/',
          {
            external: true,
            replace: true
          }
        )
      }
    },
    switchToFishing: () => {
      if (process.client) {
        const config = useRuntimeConfig()
        navigateTo(
          locale === 'uk'
            ? config.public.fishingDomain
            : config.public.fishingDomain + '/ru/',
          {
            external: true,
            replace: true
          }
        )
      }
    }
  })
}
