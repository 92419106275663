export const usePriceFormatter = () => {
  return (number, postfix = true) => {
    if (typeof number === 'string') {
      number = Number(number)
    }
    const price = new Intl.NumberFormat('uk-UA', {
      style: postfix ? 'currency' : 'decimal',
      currency: 'UAH',
      minimumFractionDigits: 0,
      maximalFractionDigits: 2
    })
    return price.format(number)?.replace('₴', 'грн')
  }
}
