import api from '../api.client'

const path = '/api/v2/shop/banners'

export default {
  getMainPage: (options = {}) =>
    api({ auth: false, ...options }).get(
      `${path}?type.code[]=main_page&type.code[]=main_page_right`
    ),
  getMenu: (options = {}) =>
    api({ auth: false, ...options }).get(`${path}?type.code=menu`),
  getCatalog: (taxonCode, options = {}) =>
    api({ auth: false, ...options }).get(
      `${path}?taxon=${taxonCode}&type.code=category_products`
    ),
  getCart: (options = {}) =>
    api({ auth: false, ...options }).get(`${path}?type.code=cart`),
  getThankYouPage: (options = {}) =>
    api({ auth: false, ...options }).get(`${path}?type.code=thank_you_page`)
}
