import api from '../api.client'

export default {
  authGoogle: ({ code, redirectUri }, options) =>
    api(options).post('/api/google/login', { code, redirectUri }),

  connectGoogle: ({ code, redirectUri }, options) =>
    api(options).post('/api/v2/shop/google/connect', { code, redirectUri }),

  removeSocialProfile: options =>
    api(options).delete('/api/v2/shop/social-profile/{id}'),

  authFacebook: (payload, options) =>
    api(options).post('/api/facebook/login', payload),

  connectFacebook: (payload, options) =>
    api(options).post('/api/v2/shop/facebook/connect', payload)
}
