import * as vueLoading from 'vue-loading-overlay'

const useLoader = (target, options = {}) => {
  if (process.server) {
    return {
      show: () => {},
      hide: () => {}
    }
  }
  const loader = vueLoading.useLoading({
    color: '#00785F',
    ...options,
    container: target?.value || target
  })
  let loading = null
  const show = (options = {}) => {
    loading = loader.show(options)
    return loading
  }
  const hide = () => {
    loading && loading?.hide()
  }
  return { show, hide }
}

export default useLoader
