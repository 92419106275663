import api from '../api.client'

const categoriesPath = '/api/v2/shop/taxons/posts_categories'
const path = '/api/v2/shop/posts'

export default {
  getAll: (params, options = {}) =>
    api({ auth: false, ...options }).get(path, { ...params }),
  getOne: (slug, options = {}) => {
    return api({ auth: false, ...options }).get(`${path}/post-by-slug/${slug}`)
  },
  getForProduct: (code, options = {}) =>
    api({ auth: false, ...options }).get(`${path}/product-variant/${code}`),
  getCategories: (options = {}) =>
    api({ auth: false, ...options }).get(categoriesPath),
  getPromotionalPage: (postSlug, taxonSlug, options = {}) =>
    api({ auth: false, ...options }).get(
      `${path}/short-data/${postSlug}/taxon/${taxonSlug}`
    ),
  getPromotionalProducts: (postId, taxonId, pagination, options = {}) =>
    api({ auth: false, ...options }).get(
      `${path}/product-variants/${postId}/taxon/${taxonId}`,
      pagination
    )
}
