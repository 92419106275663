<template>
  <NuxtLayout :head-links="false" :title="seoData?.title">
    <client-only>
      <NuxtLoadingIndicator color="#FF612F" :duration="3500" />
    </client-only>

    <div class="bg-gray h-full">
      <div class="container text-green-swamp">
        <AppBreadcrumbs :breadcrumbs="breadcrumbs" />
        <div
          :style="backgroundStyles"
          class="min-h-[370px] !bg-contain grid lg:grid-cols-2"
        >
          <nuxt-img
            class="lg:col-start-2"
            src="/images/error/background/fisher.png"
            width="625"
            format="webp"
          />
          <div class="lg:col-start-1 lg:row-start-1 mt-3 lg:mt-8">
            <p class="text-[20px] font-bold max-w-[260px]">
              {{ t('page_404_title') }}
            </p>
            <p class="text-[12px] mt-3 max-w-[260px]">
              {{ t('page_404_description') }}
            </p>

            <div
              class="relative flex rounded-md overflow-hidden drop-shadow mt-4 lg:mt-8 max-w-[420px]"
            >
              <div
                class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
              >
                <MagnifyingGlassIcon
                  class="h-5 w-5 text-green-swamp"
                  aria-hidden="true"
                />
              </div>
              <input
                id="search"
                ref="inputRef"
                v-model="query"
                name="search"
                type="search"
                class="block w-full rounded-none border-0 py-1.5 pl-10 placeholder-gray-mud focus:ring-0 sm:text-sm sm:leading-6 h-[42px]"
                :placeholder="t('Пошук')"
              />
              <button
                type="button"
                class="btn btn-primary relative -ml-px inline-flex items-center gap-x-1.5 rounded-l-none px-3 lg:px-8 py-2 uppercase text-xs font-bold h-[42px]"
                :aria-label="t('Знайти')"
                @click="onSubmitSearch()"
              >
                {{ t('Знайти') }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <TheCatalog />

      <div class="container mb-6 lg:mb-20">
        <ListWatchedProducts />
      </div>
    </div>
  </NuxtLayout>
</template>
<script setup>
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid'

const { t } = useI18n()

const { data: seoData } = await useAsyncData('seo-category-404', () =>
  useApi().seo.getByReferer({
    headers: {
      'x-referrer': new URL('/404/', useRuntimeConfig().public.originDomain)
        ?.href
    }
  })
)

useHead({
  title: seoData.value?.title || t('ІБІС'),
  link: [
    {
      rel: 'canonical',
      href: useCanonical(seoData.value?.canonical) || '/'
    }
  ],
  meta: [
    {
      property: 'og:title',
      content: seoData.value?.title || t('ІБІС')
    },
    {
      name: 'description',
      content: seoData.value?.description || t('default_seo_description')
    },
    {
      property: 'og:description',
      content: seoData.value?.description || t('default_seo_description')
    },
    {
      name: 'robots',
      content: 'noindex,nofollow'
    }
  ]
})
const $img = useImage()
const route = useRoute()
const router = useRouter()
const breadcrumbsService = useBreadcrumbsService()
const breadcrumbs = computed(() => breadcrumbsService.forPage(route, '404'))

const backgroundStyles = computed(() => {
  // const backgroundImageUrl = $img('/images/error/background/fisher.png', { width: '625', format: 'webp' })
  const background404ImageUrl = $img('/images/error/background/404.svg', {
    width: '625'
  })
  return {
    background: `url('${background404ImageUrl}') center top no-repeat`
  }
})

const query = ref('')
const onSubmitSearch = () => {
  router.push({
    path: useLocalePath()('/search'),
    query: { searchstring: query.value }
  })
}

onMounted(() => {
  console.log('title', seoData.value?.title || t('ІБІС'))
})
</script>
