
import * as ipxRuntime$4Ww8Zpbd3o from '/builds/ibis_dev/ibis_frontend/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "avatar": {
      "modifiers": {
        "fit": "cover",
        "format": "webp",
        "width": 60,
        "height": 60,
        "roundCorner": "0:100"
      }
    }
  },
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$4Ww8Zpbd3o, defaults: {} }
}
        