import 'vue-loading-overlay/dist/css/index.css'

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.directive('loading', (el, binding) => {
    if (binding.value && !el.vLoader) {
      el.vLoader = useLoader(el)
      if (!el.classList.contains('vl-parent')) {
        el.classList.add('vl-parent')
      }
      el.vLoader.show()
    } else if (!binding.value && el.vLoader) {
      el.vLoader.hide()
      el.classList.remove('vl-parent')
      el.vLoader = undefined
    }
  })
})
