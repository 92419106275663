<template>
  <NuxtLink
    class="flex flex-col items-center justify-center md:h-[126px] md:w-[126px] w-24 bg-white cursor-pointer p-3 space-y-[6px] md:space-y-[18px]"
    :class="[
      props.isActive ? 'border-element' : '',
      props.hasShadow ? 'el-shadow' : 'green-svg-icon',
      props.rounded ? 'rounded-[5px]' : '',
      props.hasBorder ? 'border-[1px] border-gray' : '',
      props.small ? 'h-[92px] w-[92px]' : ''
    ]"
    :to="
      category.slug && !props.isButton
        ? ProductVariantEntity.buildCatalogCategoryLink({
          categorySlug: category?.topTaxon?.slug
            ? category?.topTaxon?.slug
            : categorySlug,
          subCategorySlug: category.slug,
          hostname: category?.channelHostname,
          locale
        })
        : ''
    "
  >
    <slot>
      <AppExternalSvgIcon
        v-if="category?.getImageUrl()"
        :path="category?.getImageUrl()"
        :class-name="
          props.small ? '!w-[30px] !w-[30px]' : 'w-[48px] h-[48px] min-h-[48px]'
        "
      />

      <nuxt-img
        v-else
        :src="NO_IMAGE_PATH"
        width="48"
        height="20"
        format="webp"
        class="my-auto object-contain"
      />
    </slot>
    <div
      class="text-center"
      :class="[
        props.responsiveText
          ? 'md:text-[12px] md:leading-4 md:h-10 h-[30px] text-[10px] leading-3'
          : 'line-clamp-2 h-[2lh] text-[12px] leading-4 max-w-[95%]'
      ]"
    >
      {{ props.title || category.name }}
    </div>
  </NuxtLink>
</template>

<script setup>
import TaxonEntity from '~~/entities/Taxon.entity'
import ProductVariantEntity from '~/entities/ProductVariant.entity'

const { locale } = useI18n()

const props = defineProps({
  isActive: {
    type: Boolean,
    default: false
  },
  hasShadow: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  },
  rounded: {
    type: Boolean,
    default: false
  },
  responsiveText: {
    type: Boolean,
    default: false
  },
  hasBorder: {
    type: Boolean,
    default: false
  },
  small: {
    type: Boolean,
    default: false
  },
  to: {
    type: String,
    default: ''
  },
  isButton: {
    type: Boolean,
    default: false
  },
  item: {
    type: Object,
    default: () => {}
  },
  categorySlug: {
    type: String,
    default: ''
  }
})

const category = ref(new TaxonEntity(props.item))
</script>

<style scoped>
.border-element {
  position: relative;
  overflow: hidden;
}

.border-element::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  transition: border-color 0.3s ease-in-out;
  box-shadow: 0 0 0 2px #00785f;
  border-radius: 5px;
  border: 2px solid #00785f;
}
</style>
<style>
.el-shadow svg,
.green-svg-icon svg {
  color: #00785f;
}
.el-shadow:hover,
.el-shadow:hover svg * {
  color: #ff612f;
  z-index: 1;
  box-shadow: 0 10px 40px rgba(38, 38, 38, 0.05),
    0 2px 4px rgba(38, 38, 38, 0.02), 0 10px 40px rgba(38, 38, 38, 0.05),
    0 2px 4px rgba(38, 38, 38, 0.02);
}
</style>
