import values from 'lodash/values'
import groupBy from 'lodash/groupBy'
import keys from 'lodash/keys'
import { useStorage } from '@vueuse/core'
import { useAuthStore } from './auth'

export const useComparisonStore = defineStore({
  id: 'comparison-store',
  state: () => {
    return {
      requested: false,
      list: []
    }
  },
  actions: {
    async fetchComparisonOnce() {
      if (this.requested) {
        return false
      }
      await this.fetchComparisonList()
      this.requested = true
    },
    fetchComparisonList() {
      return useApi().comparison.list({
        onSuccess: res => {
          this.list = (res?.['hydra:member'] || []).map(
            ({ productVariant }) => productVariant
          )
        }
      })
    },
    addProductToComparison(product) {
      const authStore = useAuthStore()
      let mainTaxon
      useApi().productVariants.getMainTaxon(product.id, {
        onSuccess: res => {
          mainTaxon = res.mainTaxon
        }
      })
      if (authStore.loggedIn) {
        return useApi().comparison.addVariants(
          [
            {
              productVariantCode: product.code
            }
          ],
          {
            successNotification: 'Успішно додано до порівняння',
            onSuccess: () => {
              return this.fetchComparisonList()
            }
          }
        )
      }
      if (this.list.find(({ code }) => code === product.code)) {
        return true
      }
      this.list.push({
        code: product.code,
        product: {
          mainTaxon: mainTaxon || product?.product?.mainTaxon
        }
      })
      const storage = useStorage('ibis_compare_storage')
      storage.value = JSON.stringify(this.list)
    },
    removeProductFromComparison(product) {
      const authStore = useAuthStore()
      if (authStore.loggedIn) {
        return useApi().comparison.deleteVariant(product.code, {
          successNotification: 'Успішно видалено з порівняння',
          onSuccess: () => {
            return this.fetchComparisonList()
          }
        })
      }

      this.list = this.list.filter(item => {
        return item.code !== product.code
      })
      const storage = useStorage('ibis_compare_storage')
      storage.value = JSON.stringify(this.list)
    },
    removeComparison(taxonCode) {
      const authStore = useAuthStore()
      if (authStore.loggedIn) {
        return useApi().comparison.deleteVariantByTaxon(taxonCode, {
          successNotification: 'Успішно видалено з порівняння',
          onSuccess: () => {
            return this.fetchComparisonList()
          }
        })
      }

      this.list = this.list.filter(
        ({ product }) => taxonCode !== product?.mainTaxon?.code
      )

      const storage = useStorage('ibis_compare_storage')
      storage.value = JSON.stringify(this.list)
    },
    async mergeComparisonLists() {
      const storage = useStorage('ibis_compare_storage')
      const list =
        storage?.value && storage?.value !== 'undefined'
          ? JSON.parse(storage?.value)
          : []
      if (list?.length) {
        try {
          await useApi().comparison.addVariants(
            list.map(v => ({ productVariantCode: v.code }))
          )
        } finally {
          const storage = useStorage('ibis_compare_storage')
          storage.value = []
        }
      }

      this.fetchComparisonList()
    },
    clearList() {
      this.list = []
    }
  },
  getters: {
    comparisonList: state => state.list,
    comparisonTaxons() {
      return values(this.groupedByTaxons)?.map(list => {
        return {
          ...(list?.[0]?.product?.mainTaxon || {}),
          count: list?.length || 0
        }
      })
    },
    groupedByTaxons(state) {
      return (
        groupBy(
          state.list || [],
          variant => variant?.product?.mainTaxon?.code
        ) || {}
      )
    },
    getListByTaxon() {
      return taxonCode =>
        this.groupedByTaxons?.[taxonCode] ||
        (keys(this.groupedByTaxons)?.length
          ? this.groupedByTaxons?.[keys(this.groupedByTaxons)[0]]
          : [])
    },
    checkInComparison(state) {
      return productVariantCode => {
        return state.list?.find(({ code }) => code === productVariantCode)
      }
    }
  },
  hydrate(storeState) {
    const authStore = useAuthStore()
    if (!authStore.loggedIn) {
      const storage = useStorage('ibis_compare_storage')
      // @ts-expect-error: https://github.com/microsoft/TypeScript/issues/43826
      storeState.list =
        storage?.value && storage?.value !== 'undefined'
          ? JSON.parse(storage?.value)
          : []
    }
  }
})
