import isString from 'lodash/isString'

export default function (resources, options = {}) {
  // const transform = res => {
  //   const value = res?.value || res
  //   if (!value) {
  //     return res
  //   }
  //   if (isArray(value)) {
  //     return value.map(val => new Entity(val))
  //   }
  //   return new Entity(val)
  // }

  const getResource = res => (isString(res) ? useApi().get(res, options) : res)

  if (isString(resources)) {
    return getResource(resources)
  }
  return Promise.all(resources.map(getResource))
}
