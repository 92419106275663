import api from '../api.client'
const getPath = (...suffixPath) =>
  ['/api/v2/shop/preorders', ...suffixPath].filter(v => v).join('/')
const defaultPagination = {
  page: 1,
  itemsPerPage: 30
}
export default {
  getAll: (payload, options = {}) =>
    api(options).get(getPath(), { ...defaultPagination, ...payload }),
  create: (options = {}) => api(options).post(getPath(), {}),
  addItem: (token, payload, quantity = 1, options = {}) => {
    return api(options).post(getPath(token, 'items'), {
      quantity,
      productVariant: undefined,
      ...payload
    })
  }
}
