<template>
  <Menu v-slot="{ open }" as="div" class="relative inline-block text-left">
    <div>
      <MenuButton class="flex items-center gap-2 link-swamp">
        {{ props.breadcrumb.name }}
        <IconsDropdownChevron :active="open" size="small" />
      </MenuButton>
    </div>

    <AppTransitionsDropdown>
      <MenuItems
        class="absolute left-0 z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-[0_64px_134px_rgba(0,0,0,0.1)] focus:outline-none"
      >
        <template v-for="option in props.breadcrumb.options" :key="option.name">
          <div class="py-1 px-2">
            <AppBreadcrumbsLink
              :breadcrumb="option"
              class="block w-fit hover:text-primary"
            />
            <!-- </MenuItem> -->
          </div>
        </template>
      </MenuItems>
    </AppTransitionsDropdown>
  </Menu>
</template>

<script setup>
import { Menu, MenuButton, MenuItems } from '@headlessui/vue'
const props = defineProps({
  breadcrumb: {
    type: Object,
    default: () => {}
  }
})
</script>
