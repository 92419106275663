import { useAuthStore } from './auth'
import { useUserStore } from './user'
import { setCartToken } from '~/utils/helpers/auth'

export const useCartStore = defineStore({
  id: 'cart-store',
  state: () => {
    return {
      cart: null,
      show: false,
      showMiniCart: true,
      countInCart: null
    }
  },
  actions: {
    async addVariantToCart(productVariant, quantity = 1) {
      if (!this.cart) {
        await this.createCart()
      }
      return useApi().orders.addItem(
        this.cartToken,
        {
          productVariant
        },
        quantity,
        {
          onSuccess: cart => {
            this.cart = cart
            this.fetchCartItemsCount(this.cart)
            try {
              const { $gtagEvent, $eSputnikEvent } = useNuxtApp()
              const item = cart?.items.find(
                i => i.variantCode === productVariant
              )
              item && $gtagEvent.addToCart(item)
              item && $eSputnikEvent.statusCart(cart)
            } catch (e) {
              console.error(e)
            }
          }
        }
      )
    },
    async addMultipleVariantsToCart(productVariants, isPromotional = true) {
      if (!this.cart) {
        await this.createCart()
      }
      return useApi().orders.addMultipleItems(
        this.cartToken,
        {
          items: productVariants
        },
        {
          onSuccess: cart => {
            this.cart = cart
            this.fetchCartItemsCount(this.cart)
            if (!isPromotional) {
              return true
            }
            try {
              const { $gtagEvent, $eSputnikEvent } = useNuxtApp()
              const item = cart?.items.find(
                i => i.variantCode === productVariants[0].code
              )
              item && $gtagEvent.addToCart(item)
              item && $eSputnikEvent.statusCart(cart)
              const promotionalItem = cart?.items.find(
                i => i.variantCode === productVariants[1].code
              )
              promotionalItem && $gtagEvent.addToCart(promotionalItem)
            } catch (e) {
              console.error(e)
            }
          }
        }
      )
    },
    createCartIfNotExist() {
      if (!this.cart) {
        return this.createCart()
      }
    },
    createCart() {
      return useApi().orders.create({
        onSuccess: cart => {
          return this.setCart(cart)
        }
      })
    },
    clearCart() {
      this.cart = null
      this.countInCart = 0
      setCartToken(null)
    },
    addProductToCart(product) {
      this.list.push(product)
      this.fetchCartItemsCount(this.cart)
    },
    removeItem(itemId) {
      return useApi().orders.removeItem(this.cartToken, itemId, {
        onSuccess: cart => {
          if (cart?.paymentState === 'cart') {
            this.cart = cart
            const { $eSputnikEvent } = useNuxtApp()
            $eSputnikEvent.statusCart(cart)
          }
          return this.fetchCartItemsCount(this.cart)
          // return this.refreshCart()
        }
      })
    },
    removeMultipleItems(items) {
      return useApi().orders.removeMultipleItems(this.cartToken, items, {
        onSuccess: cart => {
          if (cart?.paymentState === 'cart') {
            this.cart = cart
            const { $eSputnikEvent } = useNuxtApp()
            $eSputnikEvent.statusCart(cart)
          }
          return this.fetchCartItemsCount(this.cart)
          // return this.refreshCart()
        }
      })
    },
    removeAll() {
      const { $gtagEvent } = useNuxtApp()
      try {
        this.cart.items.map(item => $gtagEvent.removeFromCart(item))
      } catch (e) {
        console.error(e)
      }
      return useApi().orders.remove(this.cartToken, {
        onFinally: () => {
          this.clearCart()
        }
      })
    },
    openCart() {
      this.show = true
    },
    closeCart() {
      this.show = false
    },
    closeMiniCart() {
      this.showMiniCart = false
    },
    changeQuantity(itemId, quantity, options) {
      if (quantity < 1) {
        return true
      }
      return useApi().orders.changeQuantity(this.cartToken, itemId, quantity, {
        onRequest: () => options.onRequest && options.onRequest(),
        onSuccess: cart => {
          this.cart = cart
          const { $eSputnikEvent } = useNuxtApp()
          $eSputnikEvent.statusCart(cart)
          options?.onSuccess && options.onSuccess(cart)
        },
        onFinally: () => options.onFinally && options.onFinally()
      })
    },
    changeMultipleQuantity(items, quantity, options) {
      if (quantity < 1) {
        return true
      }
      return useApi().orders.changeMultipleQuantity(
        this.cartToken,
        { items },
        {
          onRequest: () => options.onRequest && options.onRequest(),
          onSuccess: cart => {
            this.cart = cart
            const { $eSputnikEvent } = useNuxtApp()
            $eSputnikEvent.statusCart(cart)
            options?.onSuccess && options.onSuccess(cart)
          },
          onFinally: () => options.onFinally && options.onFinally()
        }
      )
    },
    async setCart(cart) {
      if (cart?.tokenValue) {
        await this.fetchCartItemsCount(cart)
        await this.fetchCart(cart)
        await useCartsMerge()
        !useAuthStore().loggedIn && setCartToken(cart?.tokenValue)
      } else {
        this.countInCart = 0
      }
    },
    fetchCart({ tokenValue }) {
      return useApi().orders.getOne(tokenValue, {
        onSuccess: res => {
          if (res?.paymentState === 'cart') {
            this.cart = res
          }
        },
        onError: () => {
          if (!useAuthStore().loggedIn) {
            return false
          }
        }
      })
    },
    fetchCartItemsCount({ tokenValue }) {
      if (!tokenValue) {
        return false
      }
      return useApi().orders.getCartItemsCount(tokenValue, {
        onSuccess: res => {
          this.countInCart = res.totalProducts
        },
        onError: () => {
          if (!useAuthStore().loggedIn) {
            setCartToken(null)
          }
        }
      })
    },
    refreshCart() {
      this.fetchCartItemsCount(this.cart)
      return this.fetchCart(this.cart)
    },

    updateCart(payload = {}) {
      const userStore = useUserStore()
      return useApi().orders.update(
        this.cartToken,
        {
          email: userStore.email,
          shippingAddress: {
            firstName: userStore?.user?.firstName,
            lastName: userStore?.user?.lastName,
            phoneNumber: userStore?.user?.phoneNumber,
            countryCode: 'ua'
          },
          ...payload
        },
        {
          onSuccess: () => this.fetchCartItemsCount(this.cart)
        }
      )
    },
    updateCount(count) {
      this.countInCart = count
    },
    selectPaymentMethod(paymentMethod) {
      return useApi().orders.selectPaymentMethod(
        this.cartToken,
        this.paymentId,
        paymentMethod,
        {
          onSuccess: res => {
            if (res?.paymentState === 'cart') {
              this.cart = res
            }
          }
        }
      )
    },
    selectShipmentMethod(shipmentType) {
      return useApi().orders.selectShippingMethod(
        this.cartToken,
        this.shipmentId,
        shipmentType,
        {
          onSuccess: res => {
            if (res?.paymentState === 'cart') {
              this.cart = res
            }
          }
        }
      )
    },
    updateCartDiscounts(promoCode = null, discountBonuses) {
      return useApi().orders.update(
        this.cartToken,
        {
          couponCode: promoCode,
          useDiscountBonuses: discountBonuses
        },
        {
          onSuccess: res => {
            if (res?.paymentState === 'cart') {
              this.cart = res
            }
          }
        }
      )
    }
  },
  getters: {
    cartData: state => state.cart,
    cartToken: state => state.cart?.tokenValue,
    shipmentId: state => state?.cart?.shipments?.[0]?.id,
    paymentId: state => state?.cart?.payments?.[0]?.id,
    list: state => state.cart?.items || [],
    productList: state => state.cart?.items || [],
    productPromotionList: state => state.cart?.promotions || [],
    count: state => state.cart?.items?.length,
    countItemsInCart: state => state?.countInCart,
    showCart: state => state.show,
    showMini: state => state.showMiniCart,
    totalCost: state =>
      state.cart?.itemsTotal ? state.cart?.itemsTotal / 100 : 0,
    variantInCart: state => variantId =>
      state.cart?.items.find(({ variant }) => variant.id === variantId)
  }
})
