import trim from 'lodash/trim'
export default defineNuxtPlugin(nuxtApp => {
  if (useRoute()?.meta?.layout === 'empty') {
    return true
  }

  useHead({
    script: [
      {
        key: 'helpcrunchSettings',
        innerHTML: `window.helpcrunchSettings = {
                      organization: '${nuxtApp.$config.public.helpcrunch.organization}',
                      appId: '${nuxtApp.$config.public.helpcrunch.appId}',
                    };`,
        // innerHTML: `window.helpcrunchSettings = {
        //               organization: '${nuxtApp.$config.public.helpcrunch.organization}',
        //             };`,
        tagPosition: 'bodyClose'
      },
      {
        key: 'helpcrunchSdk',
        innerHTML: `
          (function(w,d){var hS=w.helpcrunchSettings;if(!hS||!hS.organization){return;}var widgetSrc='https://embed.helpcrunch.com/sdk.js';w.HelpCrunch=function(){w.HelpCrunch.q.push(arguments)};w.HelpCrunch.q=[];function r(){if (d.querySelector('script[src="' + widgetSrc + '"')) { return; }var s=d.createElement('script');s.async=1;s.type='text/javascript';s.src=widgetSrc;(d.body||d.head).appendChild(s);}if(d.readyState === 'complete'||hS.loadImmediately){r();} else if(w.attachEvent){w.attachEvent('onload',r)}else{w.addEventListener('load',r,false)}})(window, document)
        `,
        tagPosition: 'bodyClose'
      }
    ]
  })

  if (import.meta.server) {
    return false
  }

  const { userId } = storeToRefs(useAuthStore())
  const { user } = storeToRefs(useUserStore())
  const getUserName = user => {
    return (
      user?.fullName ||
      trim((user?.lastName || '') + ' ' + (user?.firstName || ''))
    )
  }
  const isAuthorized = ref(false)

  const userData = computed(() => ({
    email: user.value?.email || '',
    name: getUserName(user.value),
    phone: user.value?.phoneNumber || '',
    company: '',
    user_id: userId.value || ''
  }))

  const updateUser = () => {
    try {
      HelpCrunch('updateUser', userData.value)
    } catch (e) {
      console.error(e)
    }
  }

  const userAuth = userId => {
    try {
      console.log('HelpCrunch@userAuth')
      HelpCrunch('userAuth', {
        user_id: userId
      })

      isAuthorized.value = true
    } catch (e) {
      console.error(e)
    }
  }

  const logout = () => {
    try {
      console.log('HelpCrunch@logout')
      HelpCrunch('logout')
      isAuthorized.value = false
    } catch (e) {
      console.error(e)
    }
  }

  watch(
    userId,
    (value, oldValue) => {
      console.log('userId changed', value)
      if (!!value && !oldValue) {
        // on sign in
        userAuth(value)
      } else if (!value && !!oldValue) {
        // on sign out
        logout()
      }
    },
    {
      immediate: true
    }
  )

  watch(
    userData,
    value => {
      console.log('userData changed')
      if (value.user_id && value.email) {
        updateUser()
      }
    },
    { deep: true }
  )

  return {
    provide: {
      helpCrunch: {
        userAuth,
        updateUser,
        logout
      }
    }
  }
})
