import api from '../api.client'

const path = '/api/v2/shop/product-watch-price'

export default {
  watchVariant: (data, options) => api(options).post(path, data),
  getVariant: (productVariantId, options) =>
    api(options).get(
      `/api/v2/shop/product-variants-watch-price/${productVariantId}`
    ),
  deleteVariant: (productVariantId, options) =>
    api(options).delete(`${path}/product/${productVariantId}`),

  getAllWatched: options => api(options).get(path)
}
