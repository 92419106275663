import api from '../api.client'
const path = '/api/v2/shop/product-view-history'

export default {
  getAll: (query = {}, options = {}) => api({ ...options }).get(path, query),
  getAllUnauthorized: (query = {}, options = {}) =>
    api({ ...options }).get(`${path}/guest`, query),
  update: (viewHistory, options = {}) =>
    api({ ...options }).post(path, viewHistory),
  remove: options => api({ ...options }).delete(path)
}
