import { cabinetNavigation } from '~~/utils/constants'
import { useMenuStore } from '~/stores/menu'

export default () => {
  const t = useNuxtApp()?.$i18n?.t
  const menuStore = useMenuStore()
  const breadcrumbs = ref([])

  const processCategory = (
    category,
    topTaxon,
    includeSubCategory,
    isActive = false
  ) => {
    reset()
    addCatalog()

    const addParentCategoryCatalog = category => {
      const parentCategoryCode =
        category?.parent && useCodeParser(category.parent)
      if (parentCategoryCode) {
        const parentCategory = menuStore.findCategoryByCode(parentCategoryCode)
        if (parentCategory) {
          const topCategory =
            topTaxon ||
            menuStore.findCategoryByCode(
              `${category.code[0]}${
                category.code[0] === '0' ? category.code[1] : 1
              }000000`
            )
          addParentCategoryCatalog(parentCategory)
          addCatalogLink(
            parentCategory.name,
            null,
            topCategory?.slug === parentCategory.slug
              ? `/${topCategory?.slug}/`
              : `/${topCategory?.slug}/${parentCategory?.slug}`
          )
        }
      }
    }

    addParentCategoryCatalog(category)
    if (includeSubCategory) {
      addParentCategory(category, topTaxon, false)
    } else if (!includeSubCategory && isActive) {
      if (isActive && category.topTaxonSlug && !category.subCategorySlug) {
        addCategory(category, false)
      } else {
        addParentCategory(category, { slug: category?.topTaxonSlug }, false)
      }
    } else {
      addCategory(category, true)
    }

    return breadcrumbs.value
  }

  const forProduct = (category, topTaxon = null) => {
    return processCategory(category, topTaxon, true)
  }

  const forCategory = (category, isActive = false) => {
    return processCategory(
      category,
      { slug: category?.topTaxonSlug },
      false,
      isActive
    )
  }

  const forCabinet = currentPage => {
    reset()
    addCabinet()
    addCategory(currentPage)
    return breadcrumbs.value
  }

  const forPage = (currentPage, name = '') => {
    reset()
    addRoute(currentPage, name)
    return breadcrumbs.value
  }

  const forCatalog = (name, options) => {
    reset()
    addCatalog()
    options && addCatalog(options[0]?.name, options?.slice(1))
    addCatalog(name, null)
    return breadcrumbs.value
  }

  const reset = () => {
    breadcrumbs.value = []
  }

  const defaultCatalogName = t('Каталог товарів')

  const defaultCatalogOptions = () => {
    return useMenuStore().menu.map(v => ({
      name: v?.name,
      to: v?.href
    }))
  }

  const addCatalog = (name = defaultCatalogName, options = null) => {
    breadcrumbs.value.push({
      name,
      to: null,
      options: options || defaultCatalogOptions()
    })
  }

  const addCatalogLink = (
    name = defaultCatalogName,
    options = null,
    link = null
  ) => {
    breadcrumbs.value.push({
      name,
      to: link,
      options: options || defaultCatalogOptions()
    })
  }

  const addCabinet = () => {
    breadcrumbs.value.push({
      name: 'Кабінет',
      to: null,
      options: cabinetNavigation
    })
  }

  const addCategory = (category, active) => {
    if (!category) {
      return breadcrumbs.value
    }
    breadcrumbs.value.push({
      name: category.name,
      to: !active && category?.slug ? `/${category?.slug}` : null
    })
    return breadcrumbs.value
  }

  const addParentCategory = (category, topCategory, active) => {
    const parentCategoryCode =
      category?.parent && useCodeParser(category.parent)
    if (parentCategoryCode) {
      const parentCategory = menuStore.findCategoryByCode(parentCategoryCode)
      if (parentCategory) {
        breadcrumbs.value.push({
          name: category.name,
          to:
            !active && category?.slug && topCategory?.slug
              ? `/${topCategory?.slug}/${category?.slug}/`
              : null
        })
      }
    }
  }

  const addRoute = (route = {}, name = '') => {
    // breadcrumbs.value.push({
    //   name: name || route.name,
    //   to: route.path || name
    // })
    breadcrumbs.value.push({
      name: name || route.name,
      to: ''
    })
    return breadcrumbs
  }

  return {
    breadcrumbs,
    forPage,
    forProduct,
    forCategory,
    forCabinet,
    forCatalog
  }
}
