<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.85743 10.7699H14.8198V1.23698H5.79847V10.7107C5.53816 10.4516 5.22215 10.2558 4.87471 10.1385V1.17993H1.17969V2.75853H3.30236V10.1385C2.91922 10.272 2.57607 10.5009 2.30501 10.8038C2.03395 11.1066 1.84384 11.4736 1.75246 11.8703C1.66108 12.267 1.67142 12.6805 1.78251 13.0721C1.8936 13.4637 2.10181 13.8206 2.38767 14.1095C2.67353 14.3983 3.02769 14.6097 3.41702 14.7237C3.80635 14.8378 4.21814 14.8508 4.61386 14.7616C5.00958 14.6724 5.3763 14.4839 5.67972 14.2137C5.98313 13.9436 6.21334 13.6005 6.34879 13.2167H13.2475V14.6769H14.8198V11.6381H6.36844C6.26107 11.3157 6.08691 11.0198 5.85743 10.7699ZM7.37082 9.1913V6.64366H13.2475V9.1913H7.37082ZM13.2475 5.06507H11.4196V2.81557H13.2475V5.06507ZM9.84727 2.81557V5.06507H7.37082V2.81557H9.84727ZM4.10819 13.2365C3.94492 13.2365 3.78533 13.1878 3.64958 13.0968C3.51383 13.0057 3.40802 12.8763 3.34554 12.7248C3.28306 12.5734 3.26672 12.4068 3.29857 12.246C3.33042 12.0852 3.40904 11.9376 3.52448 11.8217C3.63993 11.7058 3.78702 11.6268 3.94715 11.5949C4.10727 11.5629 4.27325 11.5793 4.42409 11.642C4.57493 11.7047 4.70385 11.811 4.79455 11.9473C4.88526 12.0835 4.93367 12.2438 4.93367 12.4077C4.93367 12.6275 4.8467 12.8383 4.6919 12.9937C4.53709 13.1491 4.32712 13.2365 4.10819 13.2365Z"
      fill="#7084F8"
    />
  </svg>
</template>
