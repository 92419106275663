export default defineNuxtPlugin(() => {
  const getPrice = price => {
    return String(price > 0 ? price / 100 : 0)
  }

  const pageView = () => {
    eS('sendEvent', 'PageView', {
      PageView: {}
    })
  }

  const statusCart = cart => {
    const cartItems = cart?.items?.map(item => {
      return {
        productKey: item?.variantCode,
        price: getPrice(item?.variant?.price),
        quantity: String(item?.quantity)
      }
    })
    eS('sendEvent', 'StatusCart', {
      StatusCart: cartItems,
      GUID: cart?.guidCartState
    })
  }

  const productPage = variant => {
    eS('sendEvent', 'ProductPage', {
      ProductPage: {
        productKey: variant?.code,
        price: getPrice(variant.price),
        isInStock: Number(variant.inStock)
      }
    })
  }

  const addToWishlist = variant => {
    eS('sendEvent', 'AddToWishlist', {
      AddToWishlist: {
        productKey: variant?.code,
        price: getPrice(variant.price),
        isInStock: Number(variant.inStock)
      }
    })
  }

  const mainPage = () => {
    eS('sendEvent', 'MainPage')
  }

  const notFound = () => {
    eS('sendEvent', 'NotFound')
  }

  const customerData = updatedUser => {
    eS('sendEvent', 'CustomerData', {
      CustomerData: {
        user_email: updatedUser?.email,
        user_name: updatedUser?.firstName,
        user_phone: updatedUser?.user?.phoneNumber
      }
    })
  }

  const categoryPage = category => {
    eS('sendEvent', 'CategoryPage', {
      CategoryPage: {
        categoryKey: category?.name
      }
    })
  }

  const searchRequest = query => {
    eS('sendEvent', 'SearchRequest', {
      SearchRequest: {
        search: query,
        isFound: 0
      }
    })
  }

  return {
    provide: {
      eSputnikEvent: {
        pageView,
        statusCart,
        productPage,
        addToWishlist,
        mainPage,
        notFound,
        customerData,
        categoryPage,
        searchRequest
      }
    }
  }
})
