import validate from "/builds/ibis_dev/ibis_frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import locale_45redirect_45global from "/builds/ibis_dev/ibis_frontend/middleware/localeRedirect.global.js";
import profile_45global from "/builds/ibis_dev/ibis_frontend/middleware/profile.global.js";
import router_45trailing_45slash_45global from "/builds/ibis_dev/ibis_frontend/middleware/routerTrailingSlash.global.js";
import manifest_45route_45rule from "/builds/ibis_dev/ibis_frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  locale_45redirect_45global,
  profile_45global,
  router_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/builds/ibis_dev/ibis_frontend/middleware/auth.js"),
  "category-exists": () => import("/builds/ibis_dev/ibis_frontend/middleware/categoryExists.js")
}