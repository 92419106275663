<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4854_89325)">
      <path
        d="M12.6675 7.12912C15.0154 7.12912 16 8.28415 16 10.954C16 13.6427 15.0154 14.7978 12.6675 14.7978C10.3385 14.7978 9.31598 13.6427 9.31598 10.954C9.31598 8.28415 10.3385 7.12912 12.6675 7.12912ZM3.35148 1.20249C5.69941 1.20249 6.70296 2.35752 6.70296 5.04628C6.70296 7.7161 5.69941 8.87113 3.35148 8.87113C1.02249 8.87113 0 7.7161 0 5.04628C0 2.35752 1.02249 1.20249 3.35148 1.20249ZM3.59502 14.2752C3.1882 13.9884 3.09002 13.4265 3.37544 13.0187L11.125 1.94541C11.4119 1.53553 11.9772 1.43675 12.386 1.72504C12.7929 2.0119 12.891 2.57376 12.6056 2.98159L4.85602 14.0548C4.56917 14.4647 4.00388 14.5635 3.59502 14.2752ZM1.89349 5.04628C1.89349 6.4664 2.34793 7.12912 3.35148 7.12912C4.3929 7.12912 4.80947 6.4664 4.80947 5.04628C4.80947 3.55042 4.3929 2.9445 3.35148 2.9445C2.34793 2.9445 1.89349 3.55042 1.89349 5.04628ZM11.1905 10.954C11.1905 12.393 11.6639 13.0557 12.6675 13.0557C13.7089 13.0557 14.1254 12.393 14.1254 10.954C14.1254 9.47705 13.7089 8.87113 12.6675 8.87113C11.6639 8.87113 11.1905 9.47705 11.1905 10.954Z"
        fill="#FF612F"
      />
    </g>
    <defs>
      <clipPath id="clip0_4854_89325">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
