<template>
  <button
    type="button"
    class="navigation-button"
    :class="['flex justify-center items-center']"
  >
    <IconsArrowLeft v-if="props.toLeft" />
    <IconsArrowRight v-else />
  </button>
</template>

<script setup>
const props = defineProps({
  toLeft: {
    type: Boolean,
    default: false
  }
})
</script>

<style lang="postcss" scoped>
.navigation-button {
  box-shadow: 0 10px 40px rgba(38, 38, 38, 0.05),
    0 2px 4px rgba(38, 38, 38, 0.02);

  @apply link-green rounded-full bg-[white] p-2.5 md:p-[15px] max-h-[41px] border border-gray;
}
.navigation-button > svg {
  @apply h-[11px] w-[11px];
}
</style>
