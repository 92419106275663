import api from '../api.client'

const path = '/api/v2/shop/cities'
const streetPath = '/api/v2/shop/streets'
const regionPath = '/api/v2/shop/region'

export default {
  getAll: (city, options = {}) =>
    api({ ...options }).get(`${path}/search?query=${city}`),
  getPopular: (options = {}) =>
    api({ auth: false, ...options }).get(path, { popular: true }),
  getOne: (id, options = {}) => api({ ...options }).get(`${path}/${id}`),
  getStreet: ({ street, city }, options = {}) =>
    api({ ...options }).get(
      `${streetPath}/search?query=${street}&cityRef=${city}`
    ),
  getRegion: (areaRef, options = {}) =>
    api({ ...options }).get(`${regionPath}/search?areaRef=${areaRef}`)
}
