import api from '../api.client'

const path = '/api/v2/shop/stores'
const pathStore = '/api/v2/store'

export default {
  getAll: (query, options) => api(options).get(path, query),

  login: (data, options) =>
    api(options).post(`${pathStore}/authentication-token`, data),

  refreshToken: (refreshToken, options) =>
    api(options).post(`${pathStore}/authentication-token-refresh`, {
      refresh_token: refreshToken
    }),

  getManagerList: (options = {}) => api(options).get(`${pathStore}/staff`),
  checkoutStore: (token, payload, options = {}) =>
    api(options).patch(`${pathStore}/orders/${token}/checkout`, payload),
  findCustomerBonuses: (token, phoneNumber, options = {}) =>
    api(options).patch(`${pathStore}/orders/${token}/customer`, {
      phoneNumber
    }),
  storeOrder: (token, options = {}) =>
    api(options).get(`${pathStore}/orders/${token}`)
}
