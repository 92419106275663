import address from './address.js'
import auth from './auth.js'
import avatarImages from './avatarImages.js'
import brands from './brands.js'
import categories from './categories.js'
import banners from './banners.js'
import cities from './cities'
import cms from './cms.js'
import comparison from './comparison.js'
import contactRequests from './contactRequests.js'
import customers from './customers.js'
import orders from './orders.js'
import paymentMethods from './paymentMethods.js'
import productAssociations from './productAssociations.js'
import productReviewLike from './productReviewLike.js'
import productReviews from './productReviews.js'
import products from './products.js'
import productVariants from './productVariants.js'
import productWatchPrice from './productWatchPrice.js'
import shippingMethods from './shippingMethods.js'
import socials from './socials'
import watchedProducts from './watchedProducts'
import wishlists from './wishlists.js'
import productInventories from './productInventories.js'
import news from './news.js'
import stores from './stores.js'
import settings from './settings.js'
import faq from './faq.js'
import seo from './seo.js'
import discounts from './discounts.js'
import textMistakes from './textMistakes.js'
import redirect from './redirect.js'
import preorders from './preorders.js'

export default {
  address,
  auth,
  avatarImages,
  brands,
  categories,
  banners,
  cities,
  cms,
  comparison,
  contactRequests,
  customers,
  orders,
  paymentMethods,
  productAssociations,
  productReviewLike,
  productReviews,
  products,
  productVariants,
  productWatchPrice,
  shippingMethods,
  socials,
  watchedProducts,
  wishlists,
  productInventories,
  news,
  stores,
  settings,
  faq,
  seo,
  discounts,
  textMistakes,
  redirect,
  preorders
}
