import get from 'lodash/get'
import toNumber from 'lodash/toNumber'
import Entity from './Entity'
type ApiResponse = {
  'hydra:member': any[]
  'hydra:search': any
  'hydra:totalItems': number
  'hydra:view': {
    'hydra:first': string
    'hydra:last': string
    'hydra:next': string
  }
}

export default class Collection {
  data: any[]
  search: any
  totalItems: number
  view: {
    first: string
    last: string
    next: string
  }

  page: {
    first: number
    last: number
    next: number
    current: number
  }

  constructor(apiResponse: ApiResponse, Model: any = Entity) {
    this.data = apiResponse['hydra:member'].map(v => new Model(v))
    this.search = apiResponse['hydra:search']
    this.totalItems = apiResponse['hydra:totalItems']
    this.view = {
      first: get(apiResponse, ['hydra:view', 'hydra:first'], ''),
      last: get(apiResponse, ['hydra:view', 'hydra:last'], ''),
      next: get(apiResponse, ['hydra:view', 'hydra:next'], '')
    }
    const last = toNumber(getURLParameter(this.view.last, 'page'))
    const next = toNumber(getURLParameter(this.view.next, 'page'))
    this.page = {
      first: toNumber(getURLParameter(this.view.first, 'page')),
      last,
      next,
      current: next ? next - 1 : last
    }
  }

  toJSON() {
    return { ...this }
  }
}

function getURLParameter(url: string, name: string) {
  return (RegExp(name + '=' + '(.+?)(&|$)').exec(url) || [undefined, null])[1]
}
