<template>
  <div class="catalog-container min-h-[442px] md:min-h-[385px]">
    <h1 class="catalog-header">
      {{ t('Каталог товарів') }}
    </h1>
    <ListHorizontalCategoryList
      :list="categories"
      :selected-category-id="selectedCategoryId"
      :select-category="handleSelectCategory"
    />
    <div
      class="max-md:hidden grid grid-cols-3 sm:grid-cols-5 md:grid-cols-7 lg:grid-cols-9 xl:grid-cols-10 2xl:grid-cols-12 relative justify-start min-h-[254px]"
    >
      <template v-for="item in category" :key="item.id + item?.name">
        <AppCatalogCategoryElement
          class="w-auto md:w-full h-[100px] md:h-[127px]"
          :item="item"
          :category-slug="selectedCategorySlug"
          responsive-text
          has-shadow
          has-border
        >
          <component :is="item.icon" v-if="item?.icon" />
        </AppCatalogCategoryElement>
      </template>
    </div>
    <div
      class="md:hidden grid grid-cols-3 sm:grid-cols-5 md:grid-cols-7 lg:grid-cols-9 xl:grid-cols-10 2xl:grid-cols-12 relative justify-start"
    >
      <template v-for="item in mobileCategories" :key="item.id + item?.name">
        <AppCatalogCategoryElement
          class="w-auto md:w-full min-h-[74px] h-[100px] md:h-[127px]"
          :item="item"
          :category-slug="selectedCategorySlug"
          responsive-text
          has-shadow
          has-border
        >
          <component :is="item.icon" v-if="item?.icon" />
        </AppCatalogCategoryElement>
      </template>
    </div>
    <span
      v-if="category?.length > 9"
      class="md:hidden underline text-xs mt-2"
      @click="isShowMore = !isShowMore"
    >
      {{ isShowMore ? t('Сховати') : t('Показати ще') }}
    </span>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { getCategoryById, useMenuStore } from '~/stores/menu'

const { t } = useI18n()

const categoryStore = useMenuStore()
const { categoriesList, tops } = storeToRefs(categoryStore)
const loading = ref(true)

const topCategory = computed(() => ({
  id: 0,
  name: t('ТОП-категорії'),
  slug: '',
  children: tops.value
}))
const selectedCategoryId = ref(topCategory.value.id)
const selectedCategorySlug = ref(topCategory.value.slug)

const categories = computed(
  () => [topCategory.value, ...categoriesList.value] || []
)

const category = computed(() => {
  if (selectedCategoryId.value === topCategory.value.id) {
    return topCategory.value.children
  }
  return getCategoryById(selectedCategoryId.value).value?.children || []
})

const isShowMore = ref(false)
const mobileCategories = computed(() => {
  if (isShowMore.value) {
    return category.value
  }
  return category.value?.slice(0, 9)
})

const handleSelectCategory = category => {
  selectedCategoryId.value = category.id
  selectedCategorySlug.value = category.slug
}

onMounted(() => {
  loading.value = false
})
</script>

<style lang="postcss" scoped>
.catalog-container {
  @apply container flex flex-col text-green-swamp mb-10 md:mb-[90px] overflow-hidden;
}
.catalog-header {
  @apply text-lg mb-6 uppercase font-bold md:mb-8 md:text-3xl md:leading-8;
}
</style>
