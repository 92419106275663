<template>
  <span
    v-if="html && !error"
    class="external-svg-icon__svg-wrapper my-auto w-[48px] h-auto"
    :class="`${cssColor} ${className}`"
    v-html="html"
  ></span>
  <nuxt-img
    v-else
    :src="NO_IMAGE_PATH"
    width="48"
    height="20"
    format="webp"
    class="my-auto object-contain"
    :class="`${cssColor} ${className}`"
  />
</template>

<script setup>
const props = defineProps({
  path: {
    type: String,
    default: ''
  },
  cssColor: {
    type: String,
    default: ''
  },
  className: {
    type: String,
    default: ''
  }
})
const { data: html, error } = useFetch('/api/assets?path=' + props.path, {
  lazy: true,
  server: false
})
</script>
<style>
.external-svg-icon__svg-wrapper svg {
  max-height: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: auto;
}
.external-svg-icon__svg-wrapper svg * {
  fill: currentColor !important;
}
</style>
