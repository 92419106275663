<template>
  <svg
    width="7"
    height="12"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="mx-[9px]"
  >
    <path d="M0 11.8H0.756L6.072 0.4H5.316L0 11.8Z" fill="#BDBDBD" />
  </svg>
</template>
