import isString from 'lodash/isString'
import omit from 'lodash/omit'
import Entity from './Entity'
import TaxonImageEntity from './TaxonImage.entity'
import ProductEntity from '~/entities/Product.entity'

class TaxonEntity extends Entity {
  id?: number
  code?: string
  name?: string
  slug?: string
  images?: (string | TaxonImageEntity)[]
  children?: (this | string)[]
  parent?: this | string
  channelHostname?: string
  products?: ProductEntity[]
  constructor(payload: Partial<TaxonEntity>) {
    super(omit(payload, ['children', 'parent']))
    Object.assign(this, omit(payload, ['children', 'parent', 'images']))
    if (payload?.children) {
      this.setChildren(payload.children)
    }
    if (payload?.parent) {
      this.setParent(payload.parent)
    }
    if (payload?.images) {
      this.setImages(payload.images)
    }
  }

  getImageUrl() {
    if (this?.images?.length) {
      return isString(this?.images[0])
        ? useAssetUrl(this?.images[0])
        : this?.images[0].getImageUrl()
    }
  }

  getImageData() {
    if (this?.images?.length && !isString(this?.images[0])) {
      return this?.images[0].getImageData()
    }
  }

  setChildren(children: (Partial<TaxonEntity> | string)[]) {
    this.children = children.map(c =>
      isString(c) ? c : (new TaxonEntity(c) as this)
    )
  }

  setParent(parent: Partial<TaxonEntity> | string) {
    this.parent = isString(parent) ? parent : (new TaxonEntity(parent) as this)
  }

  setImages(images: (TaxonImageEntity | string)[]) {
    this.images = images.map(image =>
      isString(image) ? image : new TaxonImageEntity(image)
    )
  }

  async getChildren() {
    this.children = this.children?.length
      ? (await useAsyncResources(this.children)).map(
          (value: Partial<TaxonEntity>) => new TaxonEntity(value)
        )
      : []
    return this.children
  }

  static async loadChildren(taxon: TaxonEntity, depth = 1) {
    taxon.children = taxon.children?.length
      ? await useAsyncResources(taxon.children)
      : []
    if (depth > 1) {
      taxon.children = await Promise.all(
        (taxon?.children || []).map(child =>
          TaxonEntity.loadChildren(child as TaxonEntity, depth - 1)
        )
      )
    }

    return taxon
  }

  link() {
    return `/${this.slug}`
  }

  categoryLink() {
    return `/${this.slug}`
  }
}

export default TaxonEntity
