/* eslint-disable no-template-curly-in-string */
import { setLocale } from 'yup'
export default defineNuxtPlugin(() => {
  setLocale({
    mixed: {
      default: 'Поле ${label} невірне',
      required: 'Обовʼязкове поле',
      notType: ({ type, path }) => {
        switch (type) {
          case 'number':
            return `${path} має складатися з цифр`
          default:
            return `Поле ${path} невірне`
        }
      }
    },
    string: {
      min: '${label} має містити принаймні ${min} символів',
      max: '${label} має містити не більше ${max} символів',
      email: 'Введено невірну адресу ел. пошти',
      matches: 'Поле ${label} невірне'
    },
    date: {
      max: '${label} не може бути у майбутньому',
      min: '${label} не може бути так далеко у минулому'
    }
  })
})
