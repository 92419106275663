import trimStart from 'lodash/trimStart'
import trimEnd from 'lodash/trimEnd'

import { NO_IMAGE_PATH } from '@/utils/constants'
// import uniq from 'lodash/uniq'

export default function (path = '') {
  if (!path) {
    return NO_IMAGE_PATH
  }
  if (path.indexOf('http://') === 0 || path.indexOf('https://') === 0) {
    return path
  }
  const {
    public: { assetUrl }
  } = useRuntimeConfig()
  return [trimEnd(assetUrl, '/'), trimStart(path, '/')].join('/')
}
