<template>
  <ListHorizontal
    v-if="productList.length"
    :title="t('Переглянуті товари')"
    :slides="productList"
    :slides-per-view="props.slidesPerView"
    :list-link="{
      label: t('Очистити історію'),
      action: () => watchedProductsStore.clearList()
    }"
    :breakpoints="props.breakpoints"
  >
    <template #default="{ slide }">
      <ProductListHorizontalItem
        class="first:border-y border-l last:border-r mb-7 bg-white"
        :product="slide"
        is-watched-product
      />
    </template>
  </ListHorizontal>
</template>

<script setup>
import { useWatchedProductsStore } from '~/stores/watchedProducts'

const { t } = useI18n()
const props = defineProps({
  slidesPerView: {
    type: [Number, String],
    default: 2
  },
  breakpoints: {
    type: Object,
    default: () => ({
      600: {
        slidesPerView: 3
      },
      900: {
        slidesPerView: 4
      },
      1050: {
        slidesPerView: 5
      },
      1200: {
        slidesPerView: 6
      },
      1350: {
        slidesPerView: 7
      },
      1500: {
        slidesPerView: 8
      }
    })
  }
})
const watchedProductsStore = useWatchedProductsStore()
const productList = computed(() => watchedProductsStore?.watchedList || [])

const page = ref(1)
const itemsPerPage = ref(20)

useLazyAsyncData('watched-products', () => {
  return watchedProductsStore.getProducts({
    page: page.value,
    itemsPerPage: itemsPerPage.value
  })
})
</script>
