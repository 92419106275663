// import { TOKEN_COOKIE_KEY } from '~~/utils/constants'
import { toast } from 'vue3-toastify'
import { v4 as uuidv4 } from 'uuid'
import ApiError from './api.error'
import { useAuthStore } from '~~/stores/auth'
import { useApiDebugger } from '~~/utils/useApiDebugger'

export default function (requestOptions = {}) {
  // const config = useRuntimeConfig()
  const authStore = useAuthStore()
  const route = useRoute()
  // console.log('route', route.fullPath)
  const reqURL = useRequestURL()
  const scCookie = useCookie(SC_TOKEN_COOKIE_KEY)

  const lang = useNuxtApp()?.$i18n?.locale?.value || 'uk'

  const isErrorStatusCode = code => code >= 400 && code <= 600
  const baseURLDefault =
    process.env.NUXT_PUBLIC_API_BASE || useRuntimeConfig().public.apiBase

  const xOrigin =
    process.env.NUXT_PUBLIC_ORIGIN || useRuntimeConfig().public.originDomain
  // const authToken = authStore.token
  const {
    onSuccess,
    onRequest,
    onError,
    onFinally,
    auth = true,
    successNotification,
    baseURL = baseURLDefault,
    accept = 'application/ld+json',
    priority = 'high'
    // ignoreOrigin = false
  } = requestOptions

  const baseHeaders = {
    Accept: accept,
    'Content-Type': 'application/json',
    'Accept-Language': lang,
    ...(requestOptions.headers || {})
  }
  // if (!ignoreOrigin) {
  baseHeaders['x-origin'] = xOrigin
  // }
  const getHeaders = headers => {
    return headers
  }
  const headers = getHeaders(baseHeaders)

  const apiInstance = $fetch.create({
    baseURL,
    // keepalive: true,
    async onRequest(context) {
      if (process.server) {
        try {
          useApiDebugger(context).start()
        } catch (e) {
          console.error(e)
        }

        // eslint-disable-next-line
        process.env['NODE_TLS_REJECT_UNAUTHORIZED'] = '0'
        // Temp fix
        // There was an error during SSR:
        /***
         * Error: unable to verify the first certificate
         *    at TLSSocket.onConnectSecure (node:_tls_wrap:1540:34)
         *    at TLSSocket.emit (node:events:513:28)
         *    at TLSSocket._finishInit (node:_tls_wrap:959:8)
         *    at ssl.onhandshakedone (node:_tls_wrap:743:12)
         *  code: 'UNABLE_TO_VERIFY_LEAF_SIGNATURE'
         */
        // Is need SSL certificate?
      }

      if (
        auth &&
        context.request?.includes &&
        !context.request?.includes('authentication-token-refresh')
      ) {
        if (authStore.isRefreshing) {
          await authStore.awaitForRefresh()
        }
        if (authStore.token) {
          context.options.headers = {
            ...context.options.headers,
            Authorization: `Bearer ${authStore.token}`
          }
        }
      }
      onRequest && onRequest()
    },
    // onRequestError({ error }) {
    // console.error('req error', error.cause)
    // },
    async onResponse({ response, ...context }) {
      if (process.server) {
        try {
          useApiDebugger(context).end()
        } catch (e) {
          console.error(e)
        }
      }

      if (response?.status && !isErrorStatusCode(response?.status)) {
        const data = await response._data
        successNotification && toast.success(successNotification)
        onSuccess && (await onSuccess(data))
        onFinally && (await onFinally(data))
      }
      return response
    }
  })
  const request = (url, options) =>
    apiInstance(url, { ...options, url, uuid: uuidv4() }).catch(error => {
      if (
        error.status === 401 &&
        authStore.token &&
        !error.request?.includes('authentication-token-refresh')
      ) {
        //   const { currentRoute } = useRouter()
        //   authStore.setIntendedRoute(currentRoute.value.fullPath)
        if (authStore.isRefreshing) {
          return apiInstance(url, options).catch(errorHandler)
        } else {
          return authStore
            ?.getNewTokens()
            ?.then(() => apiInstance(url, options))
            .catch(errorHandler)
        }
      } else if (
        error.status === 401 &&
        authStore.tokenStore &&
        !error.request?.includes('authentication-token-refresh')
      ) {
        if (authStore.isRefreshing) {
          return apiInstance(url, options).catch(errorHandler)
        } else {
          return authStore
            ?.getNewTokensStore()
            ?.then(() => apiInstance(url, options))
            .catch(errorHandler)
        }
      }
      return errorHandler(error)
    })

  const errorHandler = async error => {
    error && console.error(error)
    if (error?.response?._data) {
      const apiError = new ApiError(error)
      // const errorCode = error?.response._data.code || error?.status
      // const errorMessage =
      //   error?.response._data.message || error?.response._data.title
      // const errorData = {
      //   code: errorCode,
      //   message: errorMessage,
      //   violations: error?.response?._data?.violations
      // }
      const errorMessage = apiError.getMessage()
      errorMessage &&
        error.status !== 401 &&
        window &&
        toast.error(errorMessage, { dangerouslyHTMLString: true })
      onError && (await onError(apiError))
      onFinally && (await onFinally(apiError))
    }
    throw error
  }

  const get = (url, query) => {
    const options = {
      method: 'GET',
      query,
      priority,
      headers: {
        'x-referrer': reqURL.origin + route.fullPath,
        'x-sc-cookie': scCookie.value,
        ...headers
      }
    }
    return request(url, options)
  }

  const post = (url, data) => {
    const options = {
      method: 'POST',
      priority,
      headers: authStore.isRefreshing
        ? {
            'x-referrer': reqURL.origin + route.fullPath,
            'x-sc-cookie': scCookie.value,
            ...baseHeaders
          }
        : {
            'x-referrer': reqURL.origin + route.fullPath,
            'x-sc-cookie': scCookie.value,
            ...headers
          },
      body: JSON.stringify(data)
    }
    if (data instanceof File) {
      const formData = new FormData()
      formData.append('file', data)
      delete options.headers['Content-Type']
      options.body = formData
    }
    return request(url, options)
  }

  const patch = (url, data) => {
    const options = {
      method: 'PATCH',
      priority,
      headers: {
        'x-referrer': reqURL.origin + route.fullPath,
        'x-sc-cookie': scCookie.value,
        ...headers,
        'Content-Type': 'application/merge-patch+json'
      },
      body: JSON.stringify(data)
    }
    return request(url, options)
  }

  const put = (url, data) => {
    const options = {
      method: 'PUT',
      priority,
      headers: {
        'x-referrer': reqURL.origin + route.fullPath,
        'x-sc-cookie': scCookie.value,
        ...headers
      },
      body: JSON.stringify(data)
    }
    return request(url, options)
  }

  const destroy = url => {
    const options = {
      method: 'DELETE',
      priority,
      headers: {
        'x-referrer': reqURL.origin + route.fullPath,
        'x-sc-cookie': scCookie.value,
        ...headers
      }
    }
    return request(url, options)
  }

  return { get, post, patch, put, delete: destroy }
}
