import Entity from './Entity'
import ProductOptionEntity from './ProductOption.entity'

class ProductOptionValueEntity extends Entity {
  code?: string
  id?: number
  option?: string | ProductOptionEntity
  archived?: boolean
  value?: string
  constructor(payload: Partial<ProductOptionValueEntity>) {
    super(payload)
    Object.assign(this, payload)
  }
}

export default ProductOptionValueEntity
