import trimEnd from 'lodash/trimEnd'
export default function (canonicalPath = '', slashEnd = true) {
  const storeType = useStoreType()

  const host = computed(() => {
    return storeType.isHunting
      ? storeType.huntingDomain
      : storeType.fishingDomain
  })
  const canonical = computed(() => {
    if (canonicalPath) {
      return (
        trimEnd(
          `${host.value}/${canonicalPath !== '/' ? canonicalPath : ''}`,
          '/'
        ) + (slashEnd ? '/' : '')
      )
    }
    return `${trimEnd(host.value, '/')}/`
  })

  return canonical.value
}
