import { useAuthStore } from '~/stores/auth'
import Collection from '~/entities/Collection'
import ProductVariantEntity from '~/entities/ProductVariant.entity'

export const useWatchedProductsStore = defineStore({
  id: 'watched-products-store',
  state: () => {
    return {
      list: []
    }
  },
  actions: {
    getProducts(pagination = {}) {
      const watchedProducts = useLocalStorage(STORAGE_KEYS.WATCHED_PRODUCTS)

      if (useAuthStore().loggedIn) {
        return useApi().watchedProducts.getAll(pagination, {
          onSuccess: this.updateProductList
        })
      }

      if (watchedProducts.value !== 'undefined' && watchedProducts.value) {
        const productCodes = JSON.parse(watchedProducts.value).map(
          item => item?.productVariantCode
        )
        return useApi().watchedProducts.getAllUnauthorized(
          {
            'code[]': productCodes,
            ...pagination
          },
          { onSuccess: this.updateProductList }
        )
      }
    },

    updateProductList(res) {
      this.list = new Collection(res)?.data.map(
        item => new ProductVariantEntity(item.productVariant || item)
      )
    },

    updateProducts(code) {
      const payload = {
        viewHistory: [{ productVariantCode: code }]
      }

      if (useAuthStore().loggedIn) {
        return useApi().watchedProducts.update(payload)
      }

      const watchedProductsStorage = useLocalStorage(
        STORAGE_KEYS.WATCHED_PRODUCTS
      )
      const watchedProducts =
        watchedProductsStorage.value !== undefined
          ? JSON.parse(watchedProductsStorage.value)
          : []

      // Remove product code from currentProducts if it exists
      const filteredProducts = watchedProducts.filter(
        product => product.productVariantCode !== code
      )

      // Add product code to the end of the array
      const updatedProducts = [
        ...filteredProducts,
        {
          productVariantCode: code,
          viewedAtTimestamp: Date.now().toString().slice(0, 10)
        }
      ]
      watchedProductsStorage.value = JSON.stringify(updatedProducts)
    },

    updateAndClear() {
      const watchedProductsStorage = useLocalStorage(
        STORAGE_KEYS.WATCHED_PRODUCTS
      )
      if (
        watchedProductsStorage.value !== 'undefined' &&
        watchedProductsStorage.value
      ) {
        const watchedProducts =
          watchedProductsStorage.value !== 'undefined'
            ? JSON.parse(watchedProductsStorage.value)
            : []
        useApi().watchedProducts.update({ viewHistory: [...watchedProducts] })
        watchedProductsStorage.value = null
      }
    },

    async updateStorageBeforeLogout() {
      await this.getProducts()
      const watchedProductsStorage = useLocalStorage(
        STORAGE_KEYS.WATCHED_PRODUCTS
      )
      watchedProductsStorage.value = JSON.stringify(
        this.list?.map(item => {
          return { productVariantCode: item?.code }
        })
      )
    },

    clearList() {
      this.list = []
      if (useAuthStore().loggedIn) {
        return useApi().watchedProducts.remove()
      }
      localStorage.removeItem(STORAGE_KEYS.WATCHED_PRODUCTS)
    }
  },
  getters: {
    watchedList: state => state?.list
  }
})
