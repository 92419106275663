import omit from 'lodash/omit'
import uniqBy from 'lodash/uniqBy'
import assign from 'lodash/assign'
import isString from 'lodash/isString'
import BrandEntity from './Brand.entity'
import ProductAssociationEntity from './ProductAssociation.entity'
import ProductFeedbackEntity from './ProductFeedback.entity'
import ProductOptionEntity from './ProductOption.entity'
import ProductTaxonEntity from './ProductTaxon.entity'
import TaxonEntity from './Taxon.entity'
import ProductVariantEntity from './ProductVariant.entity'
import FaqEntity from './Faq.entity'
import Entity from './Entity'

class ProductEntity extends Entity {
  '@id'?: string
  associations?: ProductAssociationEntity[]
  averageRating?: number
  brand?: BrandEntity
  code?: string | number
  createdAt?: string
  defaultVariant?: string
  description?: string
  id?: number
  images?: string[]
  mainTaxon?: TaxonEntity
  topTaxon?: TaxonEntity
  name?: string
  options?: ProductOptionEntity[]
  productTaxons?: ProductTaxonEntity[]
  reviews?: [string | ProductFeedbackEntity]
  faq?: FaqEntity[]
  shortDescription?: string
  slug?: string
  updatedAt?: string
  archived?: boolean
  variants?: (ProductVariantEntity | string)[]
  variantsCount?: number
  constructor(payload: Partial<ProductEntity>) {
    super(omit(payload, ['brand', 'options', 'associations', 'variants']))
    assign(
      this,
      omit(payload, ['brand', 'options', 'associations', 'variants'])
    )
    if (payload?.brand) {
      this.setBrand(payload.brand)
    }
    if (payload?.options) {
      this.setOptions(payload.options)
    }
    if (payload?.associations) {
      this.setAssociations(payload.associations)
    }
    if (payload?.variants) {
      this.setVariants(payload.variants)
    }
  }

  setAssociations(payload: Partial<ProductAssociationEntity>[]) {
    this.associations = payload.map(a => new ProductAssociationEntity(a))
  }

  setBrand(payload: Partial<BrandEntity>) {
    this.brand = new BrandEntity({ ...payload })
  }

  setOptions(payload: Partial<ProductOptionEntity>[]) {
    this.options = payload.map(v => new ProductOptionEntity(v))
  }

  setVariants(payload: (Partial<ProductVariantEntity> | string)[]) {
    this.variants = payload.map(v =>
      isString(v) ? v : new ProductVariantEntity(v)
    )
  }

  getSelectOptions() {
    return this.options
      ?.filter(({ type }) => type !== 'hidden')
      .map(option => {
        // search all available values
        const values = this.variants
          ?.map(variant => {
            if (isString(variant)) {
              return undefined
            }
            const optionValue = variant?.optionValues?.find(
              optionValue => optionValue.optionCode === option.code
            )
            if (optionValue) {
              optionValue.available = variant?.inStock
            }
            return optionValue
          })
          ?.filter(v => v)
        option.values = uniqBy(values, v => v?.code)
        return option
      })
      .filter(v => v.values?.length && v.values?.length > 1)
  }

  getSelectOptionsProduct() {
    return this.options
      ?.filter(({ type }) => type !== 'hidden')
      .map(option => {
        // search all available values
        const values = this.variants
          ?.map(variant => {
            if (isString(variant)) {
              return undefined
            }
            const optionValue = variant?.optionValues?.find(
              optionValue => optionValue?.optionCode === option?.code
            )
            if (optionValue) {
              optionValue.available = variant?.inStock
              optionValue.allOptions = []
              optionValue.slug = variant.slug
              variant?.optionValues?.forEach((item, idx) => {
                optionValue.allOptions[idx] = item?.code
              })
            }
            return optionValue
          })
          ?.filter(v => !v?.archived)
        // option.values = uniqBy(values, v => v?.allOptions)
        // option.values = values?.filter(i => !i?.archived)
        // option.values = uniqBy(values, v => v?.code)
        option.values = values
        return option
      })
      .filter(v => v.values?.length && v.values?.length > 1)
  }

  findVariantsForSelectedOption({
    optionValueCode,
    optionCode
  }: {
    [key: string]: string
  }) {
    return this.variants?.filter(
      variant =>
        isString(variant) ||
        variant.optionValues?.find(
          optionValue =>
            optionValue?.code === optionValueCode &&
            optionValue?.optionCode === optionCode
        )
    )
  }

  getVariant(slug?: string) {
    if (!this.variants?.length) {
      return undefined
    }
    const variant = slug && this.findVariant(slug)
    if (variant) {
      return variant
    }
    return this.variants.find((item: ProductVariantEntity | string) => {
      if (typeof item === 'object' && item !== null) {
        return !item.archived
      }
      // @ts-ignore
      return this.variants[0]
    })
  }

  getVariantByCode(code?: string) {
    if (!this.variants?.length) {
      return undefined
    }
    let variant = code && this.findVariantByCode(code)
    if (variant) {
      return variant
    }
    code = useCodeParser(this.defaultVariant)
    variant = code && this.findVariantByCode(code)
    if (variant) {
      return variant
    }
    return this.variants.find((item: ProductVariantEntity | string) => {
      if (typeof item === 'object' && item !== null) {
        return !item.archived
      }
      // @ts-ignore
      return this.variants[0]
    })
  }

  getVariantRef(code?: string) {
    return ref(this.getVariant(code))
  }

  findVariant(slug: string) {
    return this.variants?.find(v =>
      isString(v) ? v === slug : v.slug === slug
    )
  }

  findVariantByCode(code: string) {
    return this.variants?.find(v =>
      isString(v) ? v === code : v.code === code
    )
  }

  // similar, related, special-offer
  getSimilarAssociation() {
    return this.associations?.find(a => a?.getCode() === 'similar')
  }

  getRelatedAssociation() {
    return this.associations?.find(a => a?.getCode() === 'related')
  }

  getSpecialOfferAssociation() {
    return this.associations?.find(a => a?.getCode() === 'special-offer')
  }

  async fetchFilteredVariants(query = {}) {
    if (this.variantsCount && this.variantsCount > 1) {
      const payload = {
        code: this.code,
        page: 1,
        itemsPerPage: 10,
        ...query
      }
      const res = await useApi().productVariants.getProductVariantsFiltered(
        payload,
        {
          priority: 'low'
        }
      )

      this.setOptions(res?.options)
      this.setVariants(res?.variants)
    }

    return this
  }

  toJSON() {
    return { ...this }
  }
}

export default ProductEntity
