import trim from 'lodash/trim'

export default defineNuxtRouteMiddleware(to => {
  if (process.server) {
    console.log('target path: ', to.href)
  }
  // if (to.path.includes('/ua/')) {
  //   return await useRedirect(to.path, 'Page')
  // }
  const localePath = useLocalePath()
  if (trim(to.path, '/') !== trim(localePath(to.path), '/')) {
    console.log('localeRedirect', localePath(to.path))

    return navigateTo(
      {
        path: localePath(to.path) + '/',
        query: to.query,
        redirectCode: 301
      },
      { replace: true }
    )
  }
  return true
})
