import Entity from './Entity'

class TaxonImageEntity extends Entity {
  path?: string
  type?: string

  constructor(payload: Partial<TaxonImageEntity>) {
    super(payload)
    Object.assign(this, payload)
  }

  getImageUrl() {
    return useAssetUrl(this?.path)
  }

  getImageData() {
    return useFetch(this.getImageUrl())
  }
}

export default TaxonImageEntity
