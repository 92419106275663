export default defineNuxtPlugin(() => {
  // useHead({
  //   script: [
  //     {
  //       key: 'esputnikTrackerScript',
  //       innerHTML: `
  //         !function (t, e, c, n) {
  //       var s = e.createElement(c);
  //       s.async = 1, s.src = 'https://statics.esputnik.com/scripts/' + n + '.js';
  //       var r = e.scripts[0];
  //       r.parentNode.insertBefore(s, r);
  //       var f = function () {
  //           f.c(arguments);
  //       };
  //       f.q = [];
  //       f.c = function () {
  //           f.q.push(arguments);
  //       };
  //       t['eS'] = t['eS'] || f;
  //       }(window, document, 'script', '4FD0C8C92CE24C27BCC52C239F112BF3');
  //       eS('init', {
  //         TRACKING: false
  //       });
  //       `,
  //       type: 'text/javascript',
  //       tagPosition: 'bodyClose'
  //     }
  //   ]
  // })
})
