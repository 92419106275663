import api from '../api.client'

const path = '/api/v2/shop/addresses'

export default {
  path,
  getAll: (options, params) => api(options).get(path, params),
  getOne: (id, options) => api(options).get(`${path}/${id}`),
  create: (payload, options) => api(options).post(path, payload),
  update: (id, payload, options) => api(options).put(`${path}/${id}`, payload),
  delete: (id, options) => api(options).delete(`${path}/${id}`)
}
