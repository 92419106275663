import omit from 'lodash/omit'
import reduce from 'lodash/reduce'
import { DateTime } from 'luxon'
import Entity from './Entity'
import WishlistProductEntity from './WishlistProduct.entity'

class WishlistEntity extends Entity {
  token?: string
  name?: string
  wishlistProducts?: WishlistProductEntity[]
  constructor(payload: Partial<WishlistEntity>) {
    super(omit(payload, ['wishlistProducts']))
    Object.assign(this, omit(payload, ['wishlistProducts']))
    if (payload?.wishlistProducts) {
      this.setWishlistProducts(payload.wishlistProducts)
    }
  }

  setWishlistProducts(wishlistProducts: Partial<WishlistProductEntity>[]) {
    this.wishlistProducts = wishlistProducts.map(
      wishlistProduct => new WishlistProductEntity(wishlistProduct)
    )
    this.sortProducts('dateDesc')
  }

  getTotalPrice() {
    return reduce(
      this.wishlistProducts,
      (total: number, wishlistProduct) => {
        total += (wishlistProduct?.variant?.price || 0) / 100
        return total
      },
      0
    )
  }

  getFormattedTotalPrice() {
    return usePriceFormatter()(this.getTotalPrice())
  }

  getQuantity() {
    return this.wishlistProducts?.length || 0
  }

  sortProducts(sortType: string) {
    switch (sortType) {
      case 'dateDesc':
        return this.wishlistProducts?.sort(
          (a, b) =>
            DateTime.fromSQL(b?.createdAt || '2020-01-01').valueOf() -
            DateTime.fromSQL(a?.createdAt || '2020-01-01').valueOf()
        )
      case 'priceAsc':
        return this.wishlistProducts?.sort(
          (a, b) => (a?.variant?.price || 0) - (b?.variant?.price || 0)
        )
      case 'priceDesc':
        return this.wishlistProducts?.sort(
          (a, b) => (b?.variant?.price || 0) - (a?.variant?.price || 0)
        )
      default:
        return this.wishlistProducts
    }
  }
}

export default WishlistEntity
