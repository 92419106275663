import { useCartStore } from '~~/stores/cart'
import { setCartToken } from '~/utils/helpers/auth'
import { useUserStore } from '~~/stores/user'
import Collection from '~~/entities/Collection'

function useCartsMerge() {
  const cartStore = useCartStore()
  const userStore = useUserStore()

  const currentToken = cartStore.cartToken
  if (!currentToken) {
    return true
  }
  const cookieToken = useCookie(CART_TOKEN_COOKIE_KEY).value || null
  const userTokens = userStore.carts

  setCartToken(null)

  const api = useApi()
  const orders = [{ tokenValue: cookieToken }, ...userTokens]
  return Promise.all(
    orders.map(order => checkToken(order?.tokenValue, userStore, api))
  ).then(() => userStore.clearCarts())
}

async function checkToken(token, userStore, api) {
  const currentToken = userStore.cartToken
  if (!token || !currentToken || token === currentToken) {
    return true
  }
  try {
    const items = await api.orders.getItems(token, { itemsPerPage: 1000 })
    const currentVariants = userStore.list.map(({ variant }) => variant.code)

    const variantCodes = new Collection(items).data
      .map(({ variant }) => variant.code)
      .filter(variantCode => !currentVariants.includes(variantCode))

    // add items from another cart to current one and remove extra cart
    return await Promise.all(
      variantCodes.map(variantCode =>
        api.orders.addItem(currentToken, { productVariant: variantCode })
      )
    ).then(() => {
      return api.orders.remove(token, {})
    })
  } catch (e) {
    // console.error(e)
  }

  return true
}

export default useCartsMerge
