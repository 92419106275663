export default defineNuxtPlugin(() => {
  // useHead({
  //   script: [
  //     {
  //       key: 'lookSizeScript',
  //       innerHTML: `
  //         (function(w,d,s){
  //           w['LS']=w['LS']||{};
  //           LS.api_key='89763092fa396a929eb43895fcc';
  //           var f=d.getElementsByTagName(s)[0], j=d.createElement(s);
  //           j.src='https://www.looksize.com/get_ls_js.php?k='+LS.api_key;
  //           j.async=true;
  //           f.parentNode.insertBefore(j,f);
  //         })(window,document,'script');
  //       `,
  //       type: 'text/javascript',
  //       tagPosition: 'bodyClose'
  //     }
  //   ]
  // })
})
