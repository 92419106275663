// import ProductEntity from './Product.entity'
import get from 'lodash/get'
import BrandImageEntity from './BrandImage.entity'
import Entity from './Entity'

class BrandEntity extends Entity {
  code?: string
  name?: string
  description?: string
  id?: number
  slug?: string
  url?: string
  images?: [string | BrandImageEntity]
  constructor(payload: Partial<BrandEntity>) {
    super(payload)
    Object.assign(this, payload)
  }

  getImagePath(filter = '') {
    const path = get(this, ['images', 0, 'path'], null)
    return path ? useAssetUrl(path) + `?filter=${filter}` : NO_IMAGE_PATH
  }

  getId(): string {
    return this['@id'].split('/').at(-1)
  }
}

export default BrandEntity
