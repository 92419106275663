import api from '../api.client'

export default {
  getAll: (
    query = {
      itemsPerPage: 30,
      page: 1,
      filter: ''
    },
    options = {}
  ) => api({ auth: false, ...options }).get('/api/v2/shop/brands', query),

  getTaxons: options =>
    api({ auth: false, ...options }).get(
      '/api/v2/shop/taxons/brand_categories'
    ),

  getOne: (id, options) =>
    api({ auth: false, ...options }).get(`/api/v2/shop/brands/${id}`),

  getOneBySlug: (id, options) =>
    api({ auth: false, ...options }).get(`/api/v2/shop/brands-by-slug/${id}`),

  getByTaxon: (taxonCode, options) =>
    api({ auth: false, ...options }).get(
      `/api/v2/shop/brands-by-taxon/${taxonCode}`
    )
}
