export default defineNuxtPlugin(nuxtApp => {
  // const { multisearchTracker } = useRuntimeConfig().public
  // if (multisearchTracker) {
  //   if (!window?.dataLayer) {
  //     window.dataLayer = []
  //   }
  //   useHead({
  //     script: [
  //       {
  //         key: 'multisearchTrackerScript',
  //         innerHTML: `
  //                 (function(d) {
  //                     var s = d.createElement('script');
  //                     s.defer = true;
  //                     s.src = 'https://tracker.multisearch.io/3894dae9a35f';
  //                     if (d.head) d.head.appendChild(s);
  //                 })(document);
  //               `,
  //         tagPosition: 'head'
  //       }
  //     ]
  //   })
  // }
})
