import api from '../api.client'

export default {
  list: options => api(options).get('/api/v2/shop/product-comparison-list'),

  getVariants: (query, options) =>
    api({ auth: false, ...options }).get(
      '/api/v2/shop/product-variants-comparison',
      query
    ),

  addVariants: (comparisonData, options) =>
    api(options).post('/api/v2/shop/product-comparison-list', {
      comparisonData
    }),
  deleteVariant: (productVariantCode, options) =>
    api(options).delete(
      `/api/v2/shop/product-comparison-list/product/${productVariantCode}`
    ),
  deleteVariantByTaxon: (taxonCode, options) =>
    api(options).delete(
      `/api/v2/shop/product-comparison-list/taxon/${taxonCode}`
    )
}
