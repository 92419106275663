<template>
  <NuxtLink :to="props.breadcrumb.to" class="text-xs link-swamp">
    {{ props.breadcrumb.name }}
  </NuxtLink>
</template>

<script setup>
const props = defineProps({
  breadcrumb: {
    type: Object,
    default: () => {}
  }
})
</script>
