import Entity from './Entity'
import ProductOptionValueEntity from './ProductOptionValue.entity'

type ProductOptionCode = 'the-size' | 'size' | 'color' | string

class ProductOptionEntity extends Entity {
  code?: ProductOptionCode
  createdAt?: string
  id?: number
  name?: string
  updatedAt?: string
  values?: (ProductOptionValueEntity | undefined)[]
  constructor(payload: Partial<ProductOptionEntity>) {
    super(payload)
    Object.assign(this, payload)
  }
}

export default ProductOptionEntity
