import { useComparisonStore } from './comparison'
import * as authUtils from '~/utils/helpers/auth'
import { useUserStore } from '~/stores/user'
import { useCartStore } from '~/stores/cart'
import { useWatchedProductsStore } from '~/stores/watchedProducts'

export const useAuthStore = defineStore({
  id: 'auth-store',
  state: () => {
    return {
      customerId: authUtils.getCustomerId(),
      authToken: authUtils.getToken(),
      refreshToken: authUtils.getRefreshToken(),
      authTokenStore: authUtils.getTokenStore(),
      refreshTokenStore: authUtils.getRefreshTokenStore(),
      isVisibleAuthDialog: false,
      refreshingTokenStatus: 0,
      intendedRoute: null,
      rememberCustomer: false
    }
  },
  actions: {
    async login(form, options = { redirect: true }) {
      const { $api } = useNuxtApp()
      try {
        await $api.auth.login(form, {
          headers: {
            'x-customer-action': 'login'
          },
          onSuccess: data => {
            this.authToken = data.token
            this.refreshToken = data.refresh_token
            this.customerId = data.customer
            this.rememberCustomer = options.isRemember || false
            useUserStore().fetchUserDataIfNotExist(true)
            useWatchedProductsStore().updateAndClear()
            options?.onSuccess && options.onSuccess()
          },
          onError: error => options?.onError && options.onError(error.message),
          onFinally: () => options?.onFinally && options.onFinally()
        })
      } catch (error) {
        console.error(error)
        return false
      }
      this.onSuccessLogin(options)
    },
    async loginStore(form, options = { redirect: true }) {
      const { $api } = useNuxtApp()
      try {
        await $api.stores.login(form, {
          onSuccess: data => {
            this.authTokenStore = data.token
            this.refreshTokenStore = data.refresh_token
            this.rememberCustomer = options.isRemember || false
            options?.onSuccess && options.onSuccess()
          },
          onError: error => options?.onError && options.onError(error.message),
          onFinally: () => options?.onFinally && options.onFinally()
        })
      } catch (error) {
        console.error(error)
        return false
      }
      await this.signOut()
      this.onSuccessLoginStore(options)
    },
    async google(payload, options = { redirect: true }) {
      const { $api } = useNuxtApp()
      try {
        await $api.socials.authGoogle(payload, {
          onSuccess: data => {
            this.authToken = data.token
            this.refreshToken = data.refresh_token
            this.customerId = data.customer
            this.rememberCustomer = options.isRemember || false
            useUserStore().fetchUserDataIfNotExist(true)
            useWatchedProductsStore().updateAndClear()
            options?.onSuccess && options?.onSuccess()
          },
          onError: error => options?.onError && options?.onError(error.message),
          onFinally: () => options?.onFinally && options?.onFinally()
        })
      } catch (error) {
        console.error(error)
        return false
      }
      this.onSuccessLogin(options)
    },
    async facebook(payload, options = { redirect: true }) {
      const { $api } = useNuxtApp()
      try {
        await $api.socials.authFacebook(payload, {
          onSuccess: data => {
            this.authToken = data.token
            this.refreshToken = data.refresh_token
            this.customerId = data.customer
            this.rememberCustomer = options.isRemember || false
            useUserStore().fetchUserDataIfNotExist(true)
            useWatchedProductsStore().updateAndClear()
            options?.onSuccess && options?.onSuccess()
          },
          onError: error => options?.onError && options?.onError(error.message),
          onFinally: () => options?.onFinally && options?.onFinally()
        })
      } catch (error) {
        console.error(error)
        return false
      }
      this.onSuccessLogin(options)
    },
    onSuccessLogin(options) {
      useEventBus('auth').emit('login')
      useComparisonStore().mergeComparisonLists()
      const cookieOptions = authUtils.getCookieOptions(this.rememberCustomer)

      authUtils.setCustomerId(this.customerId, cookieOptions)
      authUtils.setToken(this.authToken, cookieOptions)
      authUtils.setRefreshToken(this.refreshToken, cookieOptions)
      authUtils.setIsRememberCustomer(this.rememberCustomer)

      if (options.redirect) {
        if (this.intendedRoute) {
          navigateTo(useLocalePath()(this.intendedRoute))
          this.clearIntendedRoute()
        } else {
          navigateTo(useLocalePath()('/cabinet'))
        }
      }
      this.hideAuthDialog()
    },
    onSuccessLoginStore(options) {
      // useEventBus('auth').emit('login')
      const cookieOptions = authUtils.getCookieOptions(this.rememberCustomer)

      authUtils.setTokenStore(this.authTokenStore, cookieOptions)
      authUtils.setRefreshTokenStore(this.refreshTokenStore, cookieOptions)
      authUtils.setIsRememberCustomer(this.rememberCustomer)

      if (options.redirect) {
        if (this.intendedRoute) {
          navigateTo(useLocalePath()(this.intendedRoute))
          this.clearIntendedRoute()
        } else {
          navigateTo(useLocalePath()('/'))
        }
      }
      this.hideAuthDialog()
    },
    async register(form, options) {
      const { $api } = useNuxtApp()
      try {
        await $api.customers.createCustomer(form, options)
      } catch (error) {
        console.error(error)
      }
    },
    signOut() {
      const userStore = useUserStore()
      const cartStore = useCartStore()
      const watchedProductsStore = useWatchedProductsStore()
      const comparisonStore = useComparisonStore()
      watchedProductsStore.updateStorageBeforeLogout()
      authUtils.removeAllCookies()
      navigateTo(useLocalePath()('/'))
      cartStore.clearCart()
      userStore.clearUserData()
      comparisonStore.clearList()
      this.authToken = null
      this.refreshToken = null
      this.customerId = null
      this.rememberCustomer = false
    },
    signOutStore() {
      const cartStore = useCartStore()
      authUtils.removeAllCookies()
      navigateTo(useLocalePath()('/'))
      cartStore.clearCart()
      this.authTokenStore = null
      this.refreshTokenStore = null
      this.rememberCustomer = false
    },
    showAuthDialog(intendedRoute = null) {
      if (this.loggedInStore) {
        return false
      }
      if (intendedRoute) {
        this.setIntendedRoute(intendedRoute)
      }
      this.isVisibleAuthDialog = true
    },
    hideAuthDialog() {
      this.isVisibleAuthDialog = false
    },
    loginAfterCheckout(data) {
      this.authToken = data.token
      this.refreshToken = data.refresh_token
      this.customerId = data.customer
      this.rememberCustomer = true
      const cookieOptions = authUtils.getCookieOptions(this.rememberCustomer)
      useUserStore().fetchUserDataIfNotExist(true)
      useWatchedProductsStore().updateAndClear()

      authUtils.setCustomerId(data.customer, cookieOptions)
      authUtils.setToken(data.token, cookieOptions)
      authUtils.setRefreshToken(data.refresh_token, cookieOptions)
      authUtils.setIsRememberCustomer(this.rememberCustomer)
    },
    setTokens(token, refreshToken) {
      const cookieOptions = authUtils.getCookieOptions(
        authUtils.getIsRememberCustomer()
      )
      this.authToken = token
      this.refreshToken = refreshToken
      authUtils.setToken(token, cookieOptions)
      authUtils.setRefreshToken(refreshToken, cookieOptions)
    },
    setTokensStore(token, refreshToken) {
      const cookieOptions = authUtils.getCookieOptions(
        authUtils.getIsRememberCustomer()
      )
      this.authTokenStore = token
      this.refreshTokenStore = refreshToken
      authUtils.setTokenStore(token, cookieOptions)
      authUtils.setRefreshTokenStore(refreshToken, cookieOptions)
    },
    getNewTokens() {
      const { $api } = useNuxtApp()
      if (!this.refreshToken) {
        return false
      }
      this.refreshingTokenStatus = 1
      console.log('getNewTokens')
      return $api.auth.refreshToken(this.refreshToken, {
        onSuccess: ({ token, refresh_token: refreshToken }) => {
          this.setTokens(token, refreshToken)
          this.refreshingTokenStatus = 0
          this.clearIntendedRoute()
        },
        onError: () => {
          this.refreshingTokenStatus = 0
          this.clearIntendedRoute()
          this.signOut()
        }
      })
    },
    getNewTokensStore() {
      const { $api } = useNuxtApp()
      if (!this.refreshTokenStore) {
        return false
      }
      this.refreshingTokenStatus = 1
      console.log('getNewTokensStore')
      return $api.stores.refreshToken(this.refreshTokenStore, {
        onSuccess: ({ token, refresh_token: refreshToken }) => {
          this.setTokensStore(token, refreshToken)
          this.refreshingTokenStatus = 0
          this.clearIntendedRoute()
        },
        onError: () => {
          this.refreshingTokenStatus = 0
          this.clearIntendedRoute()
          this.signOutStore()
        }
      })
    },
    awaitForRefresh() {
      // let start = 0
      // const delay = 100
      // const end = 10 * 1000
      return until(this.isRefreshing).not.toBeTruthy({
        timeout: 10000,
        throwOnTimeout: true
      })
      // return new Promise((resolve, reject) => {
      //   const timer = setInterval(() => {
      //     start += delay
      //     if (!this.isRefreshing) {
      //       clearInterval(timer)
      //       resolve(true)
      //     } else if (start > end) {
      //       clearInterval(timer)
      //       reject(
      //         new Error('The time of the waiting for refresh token is expired!')
      //       )
      //     }
      //   }, delay)
      // })
    },
    setIntendedRoute(route) {
      this.intendedRoute = route
    },
    clearIntendedRoute() {
      this.intendedRoute = null
    }
  },
  getters: {
    token: state => state.authToken,
    refresh: state => state.refreshToken,
    tokenStore: state => state.authTokenStore,
    refreshStore: state => state.refreshTokenStore,
    userId: state => state.customerId && state.customerId.split('/').at(-1),
    loggedIn: state => state.authToken && !!state.customerId,
    loggedInStore: state => state.authTokenStore && !state.customerId,
    isShownDialog: state => state.isVisibleAuthDialog,
    isRefreshing: state => !!state.refreshingTokenStatus
  },
  hydrate(state) {
    state.customerId = authUtils.getCustomerId()
    state.authToken = authUtils.getToken()
    state.refreshToken = authUtils.getRefreshToken()
    state.authTokenStore = authUtils.getTokenStore()
    state.refreshTokenStore = authUtils.getRefreshTokenStore()
    state.isVisibleAuthDialog = false
    state.refreshingTokenStatus = 0
    state.intendedRoute = null
  }
})
