<template>
  <div
    class="mb-[30px]"
    :class="{
      'mute-left': !leftArrived,
      'mute-right': !rightArrived
    }"
  >
    <div
      ref="categoriesRef"
      class="flex flex-row space-x-[30px] items-center overflow-x-auto no-scrollbar"
    >
      <div
        v-for="category of props.list"
        :id="`categories-list-select-category-btn-${getValue(category)}`"
        :key="getValue(category)"
        class="font-bold text-[13px] whitespace-nowrap cursor-pointer"
        :class="
          props.selectedCategoryId === getValue(category)
            ? 'flex btn-primary justify-center items-center px-5 py-2  min-h-[35px] bg-orange rounded text-white'
            : 'link-bold-underline'
        "
        @click="selectCategory(category)"
      >
        <slot
          :category="category"
          :selected="props.selectedCategoryId === getValue(category)"
        >
          <span>
            {{ category.name }}
          </span>
        </slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useScroll } from '@vueuse/core'

const props = defineProps({
  list: {
    type: Array,
    default: () => []
  },
  selectedCategoryId: {
    type: Number,
    default: 0
  },
  selectCategory: {
    type: Function,
    default: () => {}
  },
  getValue: {
    type: Function,
    default: category => category.id
  }
})
const categoriesRef = ref(null)
const { arrivedState } = useScroll(categoriesRef)
const { left: leftArrived, right: rightArrived } = toRefs(arrivedState)
const selectCategory = category => {
  props.selectCategory(category)
}
</script>

<style scoped lang="postcss">
.mute-right {
  @apply relative;
}
.mute-right::after {
  content: '';
  position: absolute;
  right: -10px;
  top: 0;
  bottom: 0;
  width: 48px;
  z-index: 1;
  background: linear-gradient(270deg, #fff 26.04%, rgba(255, 255, 255, 0) 100%);
}

.mute-left {
  @apply relative;
}
.mute-left::before {
  content: '';
  position: absolute;
  left: -10px;
  top: 0;
  bottom: 0;
  width: 48px;
  z-index: 1;
  background: linear-gradient(90deg, #fff 26.04%, rgba(255, 255, 255, 0) 100%);
}
</style>
