import api from '../api.client'

const path = '/api/v2/shop/cms-plugin'
const getMediaDefaultParams = {
  enabled: true,
  page: 1,
  itemsPerPage: 30
}
export default {
  getMedia: (params, options = {}) =>
    api({ auth: false, ...options }).get(`${path}/media`, {
      ...getMediaDefaultParams,
      ...params
    }),
  getPage: (page, options = {}) =>
    api({ auth: false, ...options }).get(`${path}/page-by-slug/${page}`)
}
