import api from '../api.client'

export default {
  login: (data, options) =>
    api(options).post('/api/v2/shop/authentication-token', data),

  refreshToken: (refreshToken, options) =>
    api(options).post('/api/v2/shop/authentication-token-refresh', {
      refresh_token: refreshToken
    }),

  resetPasswordRequest: (email, options) =>
    api(options).post('/api/v2/shop/reset-password-requests', {
      email
    }),

  sendNewPassword: (
    { verificationCode, newPassword, confirmNewPassword },
    options
  ) =>
    api(options).patch(
      `/api/v2/shop/reset-password-requests/${verificationCode}`,
      {
        newPassword,
        confirmNewPassword
      }
    )
}
