export const TOKEN_COOKIE_KEY = 'ibis_token'
export const REFRESH_TOKEN_COOKIE_KEY = 'ibis_refresh_token'
export const TOKEN_STORE_COOKIE_KEY = 'ibis_store_token'
export const REFRESH_TOKEN_STORE_COOKIE_KEY = 'ibis_store_refresh_token'

export const SC_TOKEN_COOKIE_KEY = 'sc'

export const CUSTOMER_ID_COOKIE_KEY = 'ibis_customer_id'
export const CART_TOKEN_COOKIE_KEY = 'ibis_cart'

export const CART_PREORDER_TOKEN_COOKIE_KEY = 'ibis_preorder_cart'
export const REMEMBER_CUSTOMER_COOKIE_KEY = 'ibis_remember_customer'

export const NO_IMAGE_PATH = '/images/no-image.webp'

export const cabinetNavigation = [
  {
    name: 'Особисті дані',
    to: '/cabinet'
  },
  {
    name: 'Мої замовлення',
    to: '/cabinet/orders'
  },
  {
    name: 'Обране',
    to: '/cabinet/favorites'
  },
  {
    name: 'Адреси та отримувачі',
    to: '/cabinet/delivery'
  },
  {
    name: 'Знижки та бонуси',
    to: '/cabinet/discounts'
  },
  {
    name: 'Вiдгуки',
    to: '/cabinet/reviews'
  }
]

export const PRODUCT_IMAGE_FILTER = {
  AVATAR_IMAGE_THUMBNAIL: 'avatar_image_thumbnail', // 100x100
  AVATAR_MEDIUM_THUMBNAIL: 'avatar_medium_thumbnail', // 137x137
  BANNER_CART: 'banner_cart', // w760
  BANNER_CATEGORY_PRODUCTS_L: 'banner_category_products_l', // w1340
  BANNER_CATEGORY_PRODUCTS_P: 'banner_category_products_p', // h415
  BANNER_HOMEPAGE: 'banner_homepage', // 760x360
  BANNER_MENU: 'banner_menu', // w210
  BANNER_THUMBNAIL: 'banner_thumbnail', // 100x100
  BANNER_TINY: 'banner_tiny', // h235
  BANNER: 'banner', // h480
  BRAND_DETAILS_THUMBNAIL: 'brand_details_thumbnail', // 98x98
  BRAND_MENU_THUMBNAIL: 'brand_menu_thumbnail', // h30
  ORIGINAL: 'original',
  PAGE_THUMBNAIL: 'page_thumbnail', // 365x225
  PAYMENT_METHOD_TINY_THUMBNAIL: 'payment_method_tiny_thumbnail', // 23x23
  POST_PRODUCT_THUMBNAIL: 'post_product_thumbnail', // w120
  POST_THUMBNAIL: 'post_thumbnail', // 365x225
  PRODUCT_OPTION_VALUE_IMAGE_PREVIEW: 'product_option_value_image_preview', // h20
  PRODUCT_OPTION_VALUE_IMAGE_THUMBNAIL: 'product_option_value_image_thumbnail', // h44
  PRODUCT_ORDER_THUMBNAIL: 'product_order_thumbnail', // h53
  PRODUCT_REVIEW_IMAGE_THUMBNAIL: 'product_review_image_thumbnail', // h100
  PRODUCT_SMALL_THUMBNAIL: 'product_small_thumbnail', // 130x118
  PRODUCT_THUMBNAIL: 'product_thumbnail', // 390 x 335
  PRODUCT_TINY_THUMBNAIL: 'product_tiny_thumbnail', // 52x52
  SHIPPING_METHOD_TINY_THUMBNAIL: 'shipping_method_tiny_thumbnail', // 57x22
  SYLIUS_LARGE: 'sylius_large', // 640 x 480
  SYLIUS_MEDIUM: 'sylius_medium', // 240x180
  SYLIUS_SMALL: 'sylius_small' // 120x90
}

export const ORDER_STATE = {
  NEW: 'new', // Новий
  PROCESSING: 'processing', // В обробці
  SHIPPED: 'shipped', // Надіслано
  COMPLETED: 'completed', // Виконаний
  CANCELLED: 'cancelled', // Скасовано
  CANCELLED_BY_CUSTOMER: 'cancelled_by_customer', // Скасовано
  READY: 'ready', // Готовий для отримання
  REFUNDED: 'refunded' // Повернуто
}

export const PRODUCT_ASSOCIATION_TYPES = {
  RELATED: 'related',
  SIMILAR: 'similar'
}

export const STORAGE_KEYS = {
  SELECTED_REVIEW: 'ibis_selected_review',
  CURRENT_USER_REVIEWS: 'ibis_reviews_user',
  WATCHED_PRODUCTS: 'ibis_watched_products_list'
}

export const SETTINGS = {
  DEFAULT_HOTLINE_PHONE: '0 800 600 002',

  WEAPON_CHANNEL_CODE: 'weapon_channel',
  FISHING_CHANNEL_CODE: 'default',
  SOCIAL_NETWORKS_GROUP: 'social_networks',
  CONTACTS_GROUP: 'contacts',
  OPENING_HOURS: 'opening_hours',

  MESSENGER_PREFIX: 'messenger_',
  PHONE_PREFIX: 'phone_',
  COMMISSION_PREFIX: 'commission_department_',
  EXTRA_PREFIX: 'extra_',
  MAIN_OFFICE: 'main_office_',

  MESSENGER_FACEBOOK: 'messenger_facebook',
  MESSENGER_INSTAGRAM: 'messenger_instagram',
  MESSENGER_TELEGRAM: 'messenger_telegram',
  MESSENGER_VIBER: 'messenger_viber',
  MESSENGER_SKYPE: 'messenger_skype',

  PHONE_KYIVSTAR: 'phone_kyivstar',
  PHONE_VODAFONE: 'phone_vodafone',
  PHONE_LIFECELL: 'phone_lifecell',
  PHONE_LANDLINE: 'phone_landline',
  PHONE_HOTLINE: 'phone_hotline',

  EMAIL: 'email',

  COMMISSION_PHONE: 'commission_department_phone_landline',
  COMMISSION_OPENING_HOURS: 'commission_department_opening_hours',
  COMMISSION_EMAIL: 'commission_department_email',
  COMMISSION_SKYPE: 'commission_department_messenger_skype',

  GROUP_WEAPON: 'weapon',
  GROUP_FISHING: 'fishing',
  GROUP_COOKING: 'cooking',
  GROUP_SHOOTING: 'shooting',

  YOUTUBE_LINK: 'youtube_link',

  PROJECT_TITLE: 'project_title'
}
