import { storeToRefs } from 'pinia'
import flatMap from 'lodash/flatMap'
import get from 'lodash/get'
import Collection from '~/entities/Collection'
// import TaxonEntity from '~/entities/Taxon.entity'

const FISHING_ROOT = 'ibis-fishing-root'

export const useMenuStore = defineStore({
  id: 'menu-store',
  state: () => {
    return {
      categories: null,
      original: null,
      tops: null,
      categoriesPending: false
      // topCategory: {
      //   id: 0,
      //   name: useI18n().t('ТОП-категорії'),
      //   slug: '',
      //   children: []
      // }
    }
  },
  actions: {
    async fetchRootCategoriesOnce(lang = 'uk') {
      if (this.categoriesPending) {
        return true
      }
      this.categoriesPending = true
      const res = await useFetch('/api/root-categories', {
        query: { lang }
      }).catch(() => {
        this.categoriesPending = false
      })
      this.original = res.data.value
      const { data } = new Collection(res.data.value)

      this.categories = [...data]?.map(v => {
        v.relations = Object.values(v.relations)

        return v
      })
      this.categoriesPending = false

      return this.categories
    },
    async fetchTopCategories(lang = 'uk') {
      const res = await useFetch('/api/top-categories', { query: { lang } })
      const { data } = new Collection(res.data?.value)
      this.tops = [...data]

      return this.tops
    }
  },
  getters: {
    menu: state => state.categories || [],
    allProductsList: state =>
      useStoreType().isHunting ? [] : get(state.categories, FISHING_ROOT),

    flatMenu: function () {
      return flatCategories(this?.menu) || []
    },

    topCategory: function () {
      return this.tops
    },

    categoriesList: function () {
      return this.menu
    },

    mainCategory: function () {
      return get(this.menu, 0, {})
    },
    findCategoryById: function () {
      return id => this.flatMenu.find(c => c.id === id)
    },
    findCategoryByCode: function () {
      return code => this.flatMenu.find(c => c.code === code)
    },
    findCategoriesByCodesList: function () {
      return codes => this.flatMenu.filter(c => codes.includes(c.code))
    },
    findCategoryBySlug: function () {
      return slug => this.flatMenu.find(c => c.slug === slug)
    },
    findCategoryChildBySlug: function () {
      return slug => this.flatMenu.find(c => c.slug === slug)
    },
    getCategoriesStack: function () {
      return childSlug => {
        const stack = []
        let counter = 0
        let category = this.findCategoryBySlug(childSlug)
        category && stack.push(category)
        while (category?.parent && counter < 5) {
          const parentCode = useCodeParser(category.parent)
          category = this.findCategoryByCode(parentCode)
          category && stack.unshift(category)
          counter++
        }
        return stack
      }
    }
  }
})

export const getCategoryById = categoryId => {
  const { menu } = storeToRefs(useMenuStore())
  return computed({
    get: () => menu.value.find(c => c.id === categoryId),
    set: v =>
      menu.value.map(c => {
        if (c.id === categoryId) {
          return v
        }
        return c
      })
  })
}

export const flatCategories = categories => {
  return flatMap(categories, category => [
    category,
    ...flatCategories(category.children)
  ])
}
