<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4857_109368)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.04236 0.889084C1.49785 0.796741 0.981581 1.16329 0.889237 1.7078C0.796893 2.25231 1.16345 2.76859 1.70796 2.86093L2.32216 2.96509L2.89371 9.63734C2.99112 10.7782 3.9569 11.625 5.08001 11.625H12.3055C13.38 11.625 14.3164 10.8491 14.4766 9.76858L15.1051 5.51306C15.2751 4.36114 14.3649 3.37232 13.2371 3.37232H4.36437L4.2488 2.02323C4.20991 1.56918 3.86895 1.19886 3.41965 1.12266L2.04236 0.889084ZM4.88646 9.46716L4.53569 5.37232H13.1042L12.4983 9.47528C12.4876 9.54699 12.4175 9.62501 12.3055 9.62501H5.08001C4.96108 9.62501 4.8926 9.53929 4.88646 9.46716Z"
        fill="white"
      />
      <path
        d="M4.93647 12.2441C5.73306 12.2441 6.38053 12.8916 6.38053 13.6882C6.38053 14.4848 5.73306 15.1304 4.93647 15.1304C4.13988 15.1304 3.4943 14.4848 3.4943 13.6882C3.4943 12.8916 4.13988 12.2441 4.93647 12.2441Z"
        fill="white"
      />
      <path
        d="M12.3759 12.2441C13.1725 12.2441 13.82 12.8916 13.82 13.6882C13.82 14.4848 13.1725 15.1304 12.3759 15.1304C11.5793 15.1304 10.9338 14.4848 10.9338 13.6882C10.9338 12.8916 11.5793 12.2441 12.3759 12.2441Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4857_109368">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
