<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.34515 0.78598C7.72083 0.460888 8.2782 0.460888 8.65387 0.785981L10.8499 2.6863C10.9458 2.76932 11.0567 2.83333 11.1766 2.8749L13.9203 3.82654C14.3897 3.98934 14.6683 4.47204 14.5746 4.95993L14.0269 7.81188C14.003 7.93648 14.003 8.06449 14.0269 8.18909L14.5746 11.041C14.6683 11.5289 14.3897 12.0116 13.9203 12.1744L11.1766 13.1261C11.0567 13.1677 10.9458 13.2317 10.8499 13.3147L8.65387 15.215C8.2782 15.5401 7.72083 15.5401 7.34515 15.215L5.14914 13.3147C5.0532 13.2317 4.94234 13.1677 4.82247 13.1261L2.07875 12.1744C1.60937 12.0116 1.33068 11.5289 1.42438 11.041L1.9721 8.18909C1.99603 8.06449 1.99603 7.93648 1.9721 7.81188L1.42438 4.95993C1.33068 4.47204 1.60937 3.98934 2.07875 3.82654L4.82247 2.8749C4.94234 2.83333 5.0532 2.76932 5.14914 2.6863L7.34515 0.78598Z"
      fill="#78C602"
    />
  </svg>
</template>
