import api from '../api.client'

const path = '/api/v2/shop/product-variants'

export default {
  getAll: (params, options) => api(options).get(path, params),
  getOne: (code, options) => api(options).get(`${path}/${code}`),
  getRelatedProducts: ({ code, query }, options = {}) =>
    api({ auth: false, ...options }).get(`${path}/${code}/related`, query),
  getByTag: (type, query = {}, options = {}) =>
    api({ auth: false, ...options }).get(
      `${path}-by-tag/product_tag_${type}`,
      query
    ),
  subscribeAvailability: (data, options = {}) =>
    api(options).post(`${path}/in-stock-remainder`, data),
  getMainTaxon: (variantId, options = {}) =>
    api(options).get(`${path}-search-data/taxon/${variantId}`),
  getRating: (variantsId, options = {}) =>
    api(options).get(`${path}-search-data`, variantsId),

  getProductVariantsFiltered: (query, options = {}) =>
    api(options).get(`${path}-filtered`, query)
}
