<template>
  <ChevronUpIcon
    v-if="props.active"
    :class="['text-green-ibis', sizeClass, activeClass]"
    aria-hidden="true"
  />
  <ChevronDownIcon
    v-else
    :class="['text-gray-mud', sizeClass]"
    aria-hidden="true"
  />
</template>
<script setup>
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/solid'

const props = defineProps({
  active: Boolean,
  activeClass: {
    type: String,
    default: ''
  },
  size: {
    type: String,
    default: 'default'
  }
})

const sizeClass = computed(() => {
  switch (props.size) {
    case 'small':
      return 'h-3.5 w-4.5'
    default:
      return 'h-5 w-6'
  }
})
</script>
