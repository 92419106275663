<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4853_89958)">
      <path
        d="M5.6658 1H10.3325M7.99913 10.3333V5.66667M11.8491 5.70556C12.9121 6.76852 13.4436 8.05185 13.4436 9.55556C13.4436 11.0593 12.9121 12.3426 11.8491 13.4056C10.7862 14.4685 9.50284 15 7.99913 15C6.49543 15 5.2121 14.4685 4.14913 13.4056C3.08617 12.3426 2.55469 11.0593 2.55469 9.55556C2.55469 8.05185 3.08617 6.76852 4.14913 5.70556C5.2121 4.64259 6.49543 4.11111 7.99913 4.11111C9.50284 4.11111 10.7862 4.64259 11.8491 5.70556ZM11.8491 5.70556L13.4436 4.11111"
        stroke="#FFAC2F"
        stroke-width="2"
      />
    </g>
    <defs>
      <clipPath id="clip0_4853_89958">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
