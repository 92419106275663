import get from 'lodash/get'
import ProductOptionValueEntity from './ProductOptionValue.entity'
import ProductVariantAttributeValueEntity from './ProductVariantAttributeValue.entity'
import Entity from './Entity'
import Image from './Image.entity'
import TaxonEntity from './Taxon.entity'

class ProductVariantEntity extends Entity {
  id?: number
  code?: string | number
  images?: (string | Image)[]
  attributes?: ProductVariantAttributeValueEntity[]
  isStock?: boolean
  name?: string
  mainTaxon?: TaxonEntity
  topTaxon?: TaxonEntity
  optionValues?: ProductOptionValueEntity[]
  originalPrice?: number
  price?: number
  inWishlist?: boolean
  archived?: boolean
  slug?: string
  totalInWishlists?: number
  constructor(payload: Partial<ProductVariantEntity>) {
    super(payload)
    Object.assign(this, payload)
    if (payload?.optionValues) {
      this.setOptionsArchived(payload?.optionValues)
    }
  }

  setOptionsArchived(payload: Partial<ProductOptionValueEntity>[]) {
    this.optionValues = payload?.map(
      v => new ProductOptionValueEntity({ ...v, archived: this.archived })
    )
  }

  getFormattedPrice() {
    return this.price ? usePriceFormatter()(this.price / 100) : ''
  }

  getFormattedOriginalPrice() {
    return this.originalPrice
      ? usePriceFormatter()(this.originalPrice / 100)
      : ''
  }

  getDiscount() {
    return useDiscountCalculator({
      price: this.price,
      originalPrice: this.originalPrice
    })
  }

  getImageResourceLink() {
    return this?.images?.[0]
  }

  static buildProductLink(
    product: any,
    variant: any,
    linkHash = '',
    locale = '',
    externalLink = false
  ) {
    const taxonSlug = product?.topTaxon?.slug || variant?.topTaxon?.slug
    const basePath = `/${taxonSlug}/details/${variant.slug}/`
    const hostname = product?.channelHostname
    const localePath = locale === 'uk' ? '' : '/' + locale

    // const isLocalhost = useRequestURL().hostname.includes('localhost')
    const isLocalhost = false

    if (
      !isLocalhost &&
      hostname &&
      (hostname !== useRequestURL().hostname || externalLink)
    ) {
      return `https://${hostname}${localePath}${basePath}${linkHash}`
    }

    return `${basePath}${linkHash}`
  }

  static buildProductCategoryLink(product: any) {
    const basePath =
      product?.mainTaxon?.slug === product.topTaxon?.slug
        ? `/${product?.topTaxon?.slug}/`
        : `/${product?.topTaxon?.slug}/${product.mainTaxon?.slug}/`
    const hostname = product?.channelHostname

    // const isLocalhost = useRequestURL().hostname.includes('localhost')
    const isLocalhost = false

    if (!isLocalhost && hostname && hostname !== useRequestURL().hostname) {
      return `https://${hostname}${basePath}`
    }

    return basePath
  }

  static buildCatalogCategoryLink(data: any) {
    const {
      categorySlug,
      subCategorySlug,
      hostname,
      locale,
      sort,
      relationSlug
    } = data
    const basePath = subCategorySlug
      ? `/${categorySlug}/${subCategorySlug}/`
      : `/${categorySlug}/`
    const relationPath = relationSlug ? `search/${relationSlug}/` : ''
    const localePath = locale === 'uk' ? '' : '/' + locale
    const order = sort && sort.length ? `?order=${sort}` : ''

    // const isLocalhost = useRequestURL().hostname.includes('localhost')
    const isLocalhost = false

    if (!isLocalhost && hostname && hostname !== useRequestURL().hostname) {
      return `https://${hostname}${localePath}${basePath}${relationPath}${order}`
    }

    // return basePath
    return `${localePath}${basePath}${relationPath}${order}`
  }

  fetchMainImage(filter: string) {
    const prepareImage = (image: any) => useAssetUrl(get(image, ['path']))
    const res = this.getImageResourceLink()
    if (get(res, 'path')) {
      return prepareImage(get(res, 'path'))
    }
    if (res) {
      return useApi()
        .get(get(res, ['@id'], res), { filter }, { auth: false })
        .then(prepareImage)
    }
    return NO_IMAGE_PATH
  }
}

export default ProductVariantEntity
