import omit from 'lodash/omit'
import isString from 'lodash/isString'
import ProductEntity from './Product.entity'
import Entity from './Entity'

class ProductAssociationEntity extends Entity {
  '@id'?: string
  associatedProducts?: (string | ProductEntity)[]
  id?: number
  type?: string
  constructor(payload: Partial<ProductAssociationEntity>) {
    super(payload)
    Object.assign(this, omit(payload, ['associatedProducts']))

    if (payload?.associatedProducts) {
      this.setAssociatedProducts(payload?.associatedProducts)
    }
  }

  setAssociatedProducts(payload: (string | Partial<ProductEntity>)[]) {
    this.associatedProducts = payload.map(v =>
      isString(v) ? v : new ProductEntity(v)
    )
  }

  getCode() {
    return useCodeParser(this.type)
  }

  getProductCodes() {
    return this.associatedProducts?.map(p => useCodeParser(p)) || []
  }

  getProductLinks() {
    return (
      this.associatedProducts?.map(p => (isString(p) ? p : p?.['@id'])) || []
    )
  }

  // fetchProducts () {
  //   const products =
  // }
}

export default ProductAssociationEntity
