import get from 'lodash/get'
import uniq from 'lodash/uniq'

type Violation = {
  propertyPath: string
  message: string
  code?: string
}

export default class ApiError {
  message: string
  code?: number | string
  violations?: Violation[]
  constructor(error: any) {
    const errorData = get(error, ['response', '_data'])
    this.message = this.parseMessage(errorData)
    this.code = get(errorData, 'code', '') || get(error, 'status', '')
    this.violations = get(errorData, 'violations', [])
  }

  parseMessage(errorData: any) {
    return (
      get(errorData, 'hydra:title', '') ||
      get(errorData, 'message', '') ||
      get(errorData, 'title', '')
    )
  }

  getMessage() {
    return this.getViolationsCommonMessage() || this.message
  }

  getViolationsCommonMessage() {
    return uniq(
      this.violations?.map(({ message }) => message).filter(v => v)
    ).join('<br /><br />')
  }

  getViolationsObject() {
    const res: { [key: string]: string } = {}
    this.violations?.forEach((violation: Violation) => {
      res[violation?.propertyPath] = violation?.message
    })
    return res
  }
}
